/* -----------------------------------------------
   #GRID
   ----------------------------------------------- */

/// Spacing to add between each individual grid
/// unit.
///
/// @type Number

$grid-gutter: 3em !default;

/// Maximum width of a grid column.
///
/// @type Number

$grid-column-width: 0.5em * ($breakpoint-xl / 18px) !default;



/**
 * Grid Layout
 *
 * A simple grid system used to arrange content on
 * the page.
 *
 * @example markup
 *   <div class="o-grid">
 *      <div class="o-grid__text">…</div>
 *      <div class="o-grid__media">…</div>
 *      <div class="o-grid__text">…</div>
 *      <div class="o-grid__media">…</div>
 *   </div>
 */

.o-grid {
  display: flex;
  flex-direction: column;

  > .o-grid__media {
    position: relative;
    max-height: 25vmax;
  }

  > .o-grid__text {
    padding: 3%;
  }
}



/**
 * Two Column Grid Layout
 *
 * Separate text and media content into two separate
 * columns for larger media breakpoints.
 */

@media (min-width: #{$breakpoint-lg}) {

  .o-grid {
    flex-direction: row;
    flex-wrap: wrap;

    > .o-grid__media,
    > .o-grid__text {
      flex-basis: 50%;
      margin-bottom: $grid-gutter;
    }

    > .o-grid__text {
      padding: 5%;
    }

    > .o-grid__media {
      max-height: none;
      min-height: 20vw;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .o-grid--flush {

    > .o-grid__media,
    > .o-grid__text {
      margin-bottom: 0;
    }
  }
}



/**
 * Advanced Grid Layout
 *
 * Improve the readability of text in the grid, if
 * the client supports the CSS grid layout module.
 */

@supports (display: grid) {

  @media (min-width: #{em($breakpoint-lg)}) {

    .o-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: dense;
      grid-row-gap: $grid-gutter;

      &--flush {
        grid-row-gap: 0;
      }

      > .o-grid__media {
        grid-column: 2 / span 1;
      }

      > .o-grid__text {
        grid-column: 1 / span 1;
      }

      > .o-grid__media,
      > .o-grid__text {
        margin-bottom: 0;

        &:nth-child(4n+1) {
          grid-column-start: 2;
        }

        &:nth-child(4n+2) {
          grid-column-start: 1;
        }
      }
    }
  }

  @media (min-width: #{em($breakpoint-xl)}) {

    .o-grid {
      grid-template-columns: 1fr minmax(auto, #{$grid-column-width}) minmax(auto, #{$grid-column-width}) 1fr;

      > .o-grid__media {
        grid-column: 3 / span 2;
      }

      > .o-grid__text {
        grid-column: 2 / span 1;
      }

      > .o-grid__media,
      > .o-grid__text {

        &:nth-child(4n+1) {
          grid-column-start: 3;
        }

        &:nth-child(4n+2) {
          grid-column-start: 1;
        }
      }
    }
  }
}
