/* ----------------------------------------------
   #PAGINATION
   ---------------------------------------------- */

/// Text color used for pagination items.
///
/// @type Color

$pagination-text-color: $color-text-primary !default;

/// Background color used for the pagination items.
///
/// @type Color

$pagination-background-color: tint($color-primary, 97%) !default;

/// Font family used for pagination items.
///
/// @type String

$pagination-font-family: $font-family-sans !default;



/**
 * Pagination Links
 *
 * A navigation component to jump between individual
 * pages of a paginated post archive.
 *
 * @example markup
 *   <nav class="c-pagination" aria-label="Pagination">
 *     <a href="…" class="c-pagination__previous">Previous Page</a>
 *     <a href="…" class="c-pagination__next">Next Page</a>
 *     <ul class="c-pagination__list">
 *       <li class="c-pagination__item">
 *         <a href="…" class="c-pagination__link">Page 1</a>
 *       </li>
 *       <li class="c-pagination__item">
 *         <a href="…" class="c-pagination__link">Page 2</a>
 *       </li>
 *       <li class="c-pagination__item">
 *         <a href="…" class="c-pagination__link">Page 3</a>
 *       </li>
 *     </ul>
 *   </nav>
 */

.c-pagination {
  margin: -0.25rem;
  font-size: 1rem;

  &,
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

  &__list {
    list-style: none;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    order: 1;
  }

  &__previous,
  &__next,
  &__link {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    height: 2rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin: 0.25rem;
    border: 1px solid tint($pagination-text-color, 75%);
    border-radius: 0.25rem;
    color: $pagination-text-color;
    font-family: $pagination-font-family;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.5px;
    background-color: $pagination-background-color;
    transition: border-color $duration-promptly linear;

    &:hover,
    &:focus {
      border-color: tint($pagination-text-color, 50%);
    }

    &:active {
      outline: 0;
      box-shadow: inset 0 1px 2px rgba($color-shadow, 0.2);
    }

    &[disabled] {
      opacity: 0.5;
      border-color: $color-disabled;
      color: $color-text-disabled;
      background-color: $color-disabled;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &__previous {
    order: 2;

    &::before {
      content: '‹';
      margin-right: 0.25em;
    }
  }

  &__next {
    order: 3;

    &::after {
      content: '›';
      margin-left: 0.25em;
    }
  }

  &__link {

    &--is-active {
      border-color: shade($color-accent, 10%);
      color: $color-highlight;
      background-color: $color-accent;

      &:hover,
      &:focus {
        border-color: shade($color-accent, 0%);
        background-color: tint($color-accent, 15%);
      }
    }
  }
}
