/* ----------------------------------------------
   #BUTTONS
   ---------------------------------------------- */

/**
 * Button Group
 *
 * A group of buttons, nothing more.
 *
 * @example markup
 *   <div class="c-button-group">
 *     <button type="submit" class="c-button c-button--primary">…</button>
 *     <button type="reset" class="c-button">…</button>
 *   </div>
 */

.c-button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 1px solid $color-gray-89;
  border-bottom: 1px solid $color-gray-89;
  margin-top: 2em;
  margin-left: -0.5em;

  > .c-button {
    margin-left: 0.5em;
    margin-bottom: 0.5em;
  }
}



/**
 * Button
 *
 * Buttons are used to perform an action or to
 * invoke an event.
 *
 * @example markup
 *   <a href="…" class="c-button c-button--primary">
 *     …
 *   </a>
 */

.c-button {
  display: inline-block;
  vertical-align: middle;
  padding: calc(0.375em - 1px) calc(1em - 1px);
  border-radius: 0.25em;
  border: 1px solid $color-primary;
  color: $color-highlight;
  font-family: $font-family-sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: $base-line-height;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 1px 1px rgba($color-shadow, 0.1);
  letter-spacing: 1px;
  background-color: $color-primary;
  cursor: pointer;
  transition:
    border-color $duration-promptly linear,
    background-color $duration-promptly linear;

  &:hover,
  &:focus {
    border-color: tint($color-primary);
    background-color: tint($color-primary);
  }

  &:active {
    outline: 0;
    box-shadow: inset 0 1px 2px rgba($color-shadow, 0.2);
  }

  &--primary {
    border-color: $color-accent;
    background-color: $color-accent;

    &:hover,
    &:focus {
      border-color: tint($color-accent);
      background-color: tint($color-accent);
    }
  }

  &--secondary {
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    border-color: transparent;
    color: $color-text-primary;
    text-transform: none;
    text-shadow: none;
    text-decoration: underline;
    letter-spacing: 0;
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: transparent;
    }

    &:active {
      box-shadow: none;
      transform: translateY(1px);
    }
  }
}
