// -----------------------------------------------
// #ELEVATE
// -----------------------------------------------

/// Add a box shadow to floating elements following
/// the suggestions of the Material Design
/// style guide.
///
/// @see {https://material.io/guidelines/material-design/elevation-shadows.html}
///
/// @example scss
///   .c-card {
///     @include elevate(1);
///   }
///
/// @example markup
///   <div class="c-card">
///     …
///   </div>
///

@mixin elevate($depth: 1) {
  @if $depth == 0 {
    box-shadow: none;
  } @else if $depth == 1 {
    box-shadow: 0 1px 3px rgba($color-shadow, 0.04), 0 1px 2px rgba($color-shadow, 0.08);
  } @else if $depth == 2 {
    box-shadow: 0 3px 6px rgba($color-shadow, 0.05), 0 3px 6px rgba($color-shadow, 0.07);
  } @else if $depth == 3 {
    box-shadow: 0 10px 20px rgba($color-shadow, 0.06), 0 6px 6px rgba($color-shadow, 0.07);
  } @else if $depth == 4 {
    box-shadow: 0 14px 28px rgba($color-shadow, 0.07), 0 10px 10px rgba($color-shadow, 0.05);
  } @else if $depth == 5 {
    box-shadow: 0 19px 38px rgba($color-shadow, 0.07), 0 15px 12px rgba($color-shadow, 0.05);
  } @else {
    @warn 'Given depth is not supported'; // sass-lint:disable-line no-warn
  }
}
