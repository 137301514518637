/* -----------------------------------------------
   #LIST-INLINE
   ----------------------------------------------- */

/// Amount of spacing added between each list item.
///
/// @type Number

$list-gutter: 1em !default;



/**
 * Inline List
 *
 * The inline-list object simply displays a list of
 * items in a row.
 *
 * @example markup
 *   <ul class="o-list-inline">
 *     <li class="o-list-inline__item">…</li>
 *     <li class="o-list-inline__item">…</li>
 *     <li class="o-list-inline__item">…</li>
 *   </ul>
 */

.o-list-inline {
  margin-left: -$list-gutter;

  &__item {
    display: inline-block;
    margin-left: $list-gutter;
  }
}


/**
 * Vertical alignment modifiers
 *
 * A set of modifiers used to change the alignment
 * of list items.
 */

/**
 * Align all list items to the middles of each
 * other.
 */

.o-list-inline--middle {

  > .o-list-inline__item {
    vertical-align: middle;
  }
}

/**
 * Align all list items to the bottoms of each
 * other.
 */

.o-list-inline--bottom {

  > .o-list-inline__item {
    vertical-align: bottom;
  }
}
