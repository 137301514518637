/* ----------------------------------------------
   #TILES
   ---------------------------------------------- */

/// Font family used for a tile label.
///
/// @type String

$tile-font-family: $font-family-sans !default;

/// The font size of the tile label.
///
/// @type Number

$tile-font-size: $font-size-h4 !default;



/**
 * Tile Grid
 *
 * A simple container used to group a set of
 * related tiles.
 *
 * @example markup
 *   <div class="c-tile-grid">
 *     <div class="c-tile">…</div>
 *     <div class="c-tile">…</div>
 *   </div>
 */

.c-tile-grid {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  margin-bottom: 3em;

  @media (min-width: #{$breakpoint-lg}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -1em;
  }

  > .c-tile {
    margin-top: 1em;

    @media (min-width: #{$breakpoint-lg}) {
      flex-basis: calc(50% - 1em);
      margin-left: 1em;
    }
  }
}



/**
 * Tile Component
 *
 * A simple disclosure widget, where the visibility
 * of the inner content can be toggled on click.
 *
 * @example markup
 *   <div class="c-tile">
 *     …
 *   </div>
 */

.c-tile {
  grid-column: span 12;
  position: relative;
  overflow: hidden;
  display: block;
  padding: 3%;
  border: 1px solid tint($color-text-primary, 90%);
  font-size: 1rem;

  &__label {
    float: left;
    position: relative;
    top: -0.375rem;
    margin-bottom: -0.25rem;
    margin-right: 0.75rem;
    font-family: $tile-font-family;
    font-size: $tile-font-size;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    &::after {
      content: '.';
    }
  }
}



/**
 * Color Variations
 *
 * A set of modifiers used to customize the color of
 * a tile component.
 */

.c-tile--tint {
  border-color: shade($color-primary, 2%);
  color: $color-text-primary;
  background-color: $color-primary;

  > .c-card__header {
    border-bottom-width: 2px;
    border-bottom-color: shade($color-primary, 5%);
  }
}
