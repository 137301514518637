/* -----------------------------------------------
   #HEADINGS
   ----------------------------------------------- */

/**
 * A set of utility functions used to style a
 * heading independent from the used HTML tag.
 */

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  margin-bottom: 1rem;
  font-family: $font-family-sans;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
}

.u-h1,
.u-h2,
.u-h3 {

  &:not(:first-child) {
    margin-top: 3rem;
  }
}

.u-h1 {
  @include fluid-type($breakpoint-xs, $breakpoint-xl, $font-size-xl, $font-size-h1);
}

.u-h2 {
  font-size: $font-size-h2;
}

.u-h3 {
  font-size: $font-size-h3;
  letter-spacing: 0.5px;
}

.u-h4 {
  font-size: $font-size-h4;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: none;
}

.u-h5 {
  position: relative;
  top: -0.25rem;
  float: left;
  margin-right: 0.75rem;
  margin-bottom: -0.25rem;
  font-size: $font-size-h4;

  &::after {
    content: '.';
  }
}
