// -----------------------------------------------
// #VISIBILITY
// -----------------------------------------------

/// Visually hide an element, but keep it accessible
/// for screen-readers.
///
/// @link https://snook.ca/s/992 Hiding Content for Accessibility
///
/// @example scss
///   .u-visuall-hidden {
///     @include visually-hidden;
///   }
///
/// @example markup
///   <button>
///     <svg><use xlink:href="#menu"/></svg>
///     <span class="u-visually-hidden">Open the Navigation</span>
///   </button>
///

@mixin visually-hidden($focusable: false) {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;

  @if $focusable == true {
    &:active,
    &:focus {
      position: absolute;
      overflow: visible;
      clip: auto;
      width: auto;
      height: auto;
      margin: 0;
    }
  }
}
