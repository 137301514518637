// -----------------------------------------------
// #COLORS
// -----------------------------------------------

// BRAND COLORS:
// ----------------------------------------------
// Brand colors use English names to make
// changing their color values easier. These
// variables should not be used directly in your
// components use the variables from the UI
// section instead. You can get color name
// suggestions from:
// http://chir.ag/projects/name-that-color
// -----------------------------------------------

$color-morning-glory: #9CDFD9;
$color-alto:          #dbdbdb;
$color-juniper:       #798f93;
$color-rose-bud:      #FBB796;
$color-new-york-pink: #d87476;
$color-rouge:         #a63c60;
$color-radical-red:   #FD3E5C;
$color-ming:          #336f78;
$color-sherpa-blue:   #004E5B;
$color-minsk:         #343377;


$color-royal-blue:    #3273dc;
$color-fruit-salad:   #4CAF50;
$color-gorse:         #FFEB3B;
$color-pomegranate:   #F5480E;



// NEUTRAL COLORS:
// -----------------------------------------------
// Neutral colors use a numbered system to
// define variable names. The figure in the
// variable name represents the lightness of the
// color.
// -----------------------------------------------

$color-shadow:     #000;
$color-highlight:  #FFF;

$color-gray-15:    adjust-color($color-shadow, $lightness: 15%);
$color-gray-27:    adjust-color($color-shadow, $lightness: 27%);
$color-gray-32:    adjust-color($color-shadow, $lightness: 32%);
$color-gray-67:    adjust-color($color-shadow, $lightness: 67%);
$color-gray-73:    adjust-color($color-shadow, $lightness: 73%);
$color-gray-89:    adjust-color($color-shadow, $lightness: 89%);
$color-gray-97:    adjust-color($color-shadow, $lightness: 97%);



// SOCIAL COLORS:
// -----------------------------------------------
// Social color variables can be used to manage
// social media properties without scattering
// their brand colors throughout the stylesheet.
// -----------------------------------------------

$color-social-facebook:  #3B5998;
$color-social-twitter:   #1DA1F2;
$color-social-linkedin:  #0077B5;
$color-social-youtube:   #CD201F;
$color-social-vimeo:     #1AB7EA;
$color-social-medium:    #00AB6C;
$color-social-tumblr:    #36465D;
$color-social-flickr:    #FF0084;
$color-social-instagram: #E4405F;
$color-social-pinterest: #BD081C;
$color-social-dribbble:  #EA4C89;



// UI COLORS:
// -----------------------------------------------
// These are descriptive color variables that
// should be used by the individual components
// of your site/application.
// -----------------------------------------------

$color-primary:           $color-morning-glory; //......... Color most widely used across components
$color-secondary:         $color-radical-red; // .......... Indicate related action or information.
$color-accent:            $color-radical-red; // .......... Emphasize elements with a highlight color.

$color-info:              $color-royal-blue; // ........... Color used to give neutral user feedback.
$color-success:           $color-fruit-salad; // .......... Color used to give possitive user feedback.
$color-warning:           $color-gorse; // ................ Color used to grap user attention.
$color-error:             $color-pomegranate; // .......... Color used to highlight error states.
$color-disabled:          $color-gray-89; // .............. Color used for disabled input controls.
$color-focus:             $color-royal-blue; // ........... Color of an element receiving focus.

$color-text-primary:      $color-sherpa-blue; // .......... Most important text on the screen.
$color-text-secondary:    $color-juniper; // .............. Text that is lower in the visual hierarchy.
$color-text-disabled:     $color-gray-67; // .............. Disabled text, hint text and icons.

$color-input-border:      $color-alto; // ................. Color used for the border of input elements.
$color-input-disabled:    $color-disabled; // ............. Color of disabled input elements.

$color-background-light:  $color-morning-glory; // ........ A light color used as background color.
$color-background-dark:   $color-sherpa-blue; // .......... A dark color used as background color.
