/* -----------------------------------------------
   #SKIP
   ----------------------------------------------- */

/// Text color of skip links.
///
/// @type Color

$skip-link-text-color: $color-highlight !default;

/// Background color of skip links.
///
/// @type Color

$skip-link-background-color: $color-accent !default;

/// Font family used for skip links.
///
/// @type String

$skip-link-font-family: $font-family-sans !default;



/**
 * Skip Links
 *
 * Skip links are internal page links which aid
 * navigation around the current page. They are
 * mainly used by screen reader users for bypassing
 * repetitive content.
 *
 * @example markup
 *   <a href="#content" class="c-skip-link">
 *     Skip to main content
 *   </a>
 */

.c-skip-link {
  font-family: $skip-link-font-family;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: translate(-50%, -100%);
  transition: transform $duration-quickly;
  @include visually-hidden($focusable: true);

  &:active,
  &:focus {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: $layer-control;
    padding: 2.75em 1em 0.75em;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: $skip-link-text-color;
    background-color: $skip-link-background-color;
    transform: translate(-50%, 0);
    @include elevate(5);
  }
}
