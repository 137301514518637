/* -----------------------------------------------
   #DOCUMENT
   ----------------------------------------------- */

/**
 * Styling of the document root. Establishes the
 * vertical baseline grid and customizes the
 * default `font-size` and `line-height` used.
 */

html {
  height: 100%;
  color: $color-text-primary;
  font-family: $font-family-primary;
  font-size: percentage($base-font-size / 16px);
  line-height: $base-line-height;
  background-color: $color-highlight;
}

body {
  position: relative;
  min-height: 100%;
  @include fluid-type($breakpoint-xs, $breakpoint-lg, $base-font-size, 1.125 * $base-font-size);
}
