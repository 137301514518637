/* -----------------------------------------------
   #PROFILES
   ----------------------------------------------- */

/**
 * Profile List
 *
 * A group of related user profiles.
 *
 * @example markup
 *   <div class="c-profile-group">
 *     <div class="c-profile">
 *       …
 *     </div>
 *     <div class="c-profile">
 *       …
 *     </div>
 *     <div class="c-profile">
 *       …
 *     </div>
 *   </div>
 */

.c-profile-group {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  margin-top: 3em;
  margin-bottom: 3em;

  @media (min-width: #{$breakpoint-sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: #{$breakpoint-lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__name {
    grid-column: 1 / -1;
  }
}



/**
 * User Profile
 *
 * A summary of the contact details of a generic
 * person.
 *
 * @example markup
 *   <div class="c-profile">
 *     <h2 class="c-profile__name">
 *       Max Musterfrau
 *     </h2>
 *     <div class="c-profile__role">
 *        Administrator
 *     </div>
 *     <a href="…" class="c-profile__thumb">
 *       <img src="…" alt=""/>
 *     </a>
 *   </div>
 */

.c-profile {
  display: inline-flex;
  flex-direction: column;

  &__name {
    margin-bottom: 1rem;
  }

  &__role {
    margin-top: -1rem;
    margin-bottom: 1rem;
    color: $color-text-secondary;
  }

  &__thumb {
    order: -1;
    margin-bottom: 1em;

    > img {
      width: 100%;
    }
  }
}
