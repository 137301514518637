/* -----------------------------------------------
   #LAYOUT
   ----------------------------------------------- */

/// Spacing to add between each individual grid
/// unit.
///
/// @type Number

$layout-gutter: 3em !default;



/**
 * Layout Grid
 *
 * The layout grid separates the main content from
 * complementary contents.
 *
 * @example markup
 *   <main class="o-layout">
 *     <div class="o-layout__main>
 *       …
 *     </div>
 *     <div class="o-layout__aside>
 *       …
 *     </div>
 *   </main>
 */

.o-layout {
  display: flex;
  margin-top: $layout-gutter;
  margin-bottom: $layout-gutter;

  @media (max-width: #{$breakpoint-lg}) {
    flex-direction: column;

    &__aside {
      padding-top: $layout-gutter;
    }
  }

  @media (min-width: #{$breakpoint-lg}) {
    flex-direction: row;

    &__main {
      flex-basis: percentage(2/3);
    }

    &__aside {
      flex-basis: percentage(1/3);
      padding-left: $layout-gutter;
    }
  }
}
