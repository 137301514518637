/* -----------------------------------------------
   #MARKDOWN
   ----------------------------------------------- */

/**
 * Markdown Content Block
 *
 * Create a new styling context for any free-text
 * markdown content.
 */

.s-md-content {

  > :not([class]):not(:last-child) {
    margin-bottom: 1em;
  }

  > h2,
  > h3,
  > h4 {
    color: $color-primary;
  }

  > hr {
    margin-top: 2rem;
    margin-bottom: 1rem;
    border: none;
    height: 1px;
    background-color: $color-text-secondary;
  }

  ul {
    list-style: disc;
    padding-left: 1rem;
    color: $color-primary;
    font-family: $font-family-sans;
    font-size: 1.125em;
    letter-spacing: 0.25px;

    > li {
      padding-left: 0.5rem;
    }
  }

  a {
    color: $color-accent;
    transition: color $duration-promptly linear;

    &:hover,
    &:focus {
      color: tint($color-accent, 20%);
    }

    &:active {
      outline: 0;
      color: shade($color-accent, 10%);
    }
  }
}
