/* -----------------------------------------------
   #VISIBILITY
   ----------------------------------------------- */

/**
 * Visually hide content
 *
 * A utility class used to hide content visually but
 * keep it for screen reader users.
 *
 * @example markup
 *   <a href="http://twitter.com/hksagentur">
 *     <svg>…</svg>
 *     <span class="u-sr-only">
 *       H&K+S on Twitter
 *     </span>
 *   </a>
 *
 */

.u-sr-only {
  @include visually-hidden;
}
