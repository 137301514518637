/* -----------------------------------------------
   #REBOOT
   ----------------------------------------------- */

/**
 * Remove margins and paddings from elements of
 * any type using the univeral selector. Set
 * some opinionated defaults on other elements.
 *
 * @link https://css-tricks.com/?p=6 Reset all Margin & Padding
 */

* {
  margin: 0;
  padding: 0;
}

img,
svg {
  vertical-align: middle;
}

img {
  border-style: none;
  font-style: italic;
}

ul,
ol {
  list-style: none;
}

main {
  min-height: 100vh;
}

details,
summary {
  display: block;
}

strong {
  font-weight: bold;
}

small {
  font-size: 1em;
}

fieldset {
  min-width: 0;
  border: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

button {
  border: 0;
  color: inherit;
  font: inherit;
  text-transform: none;
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

a,
button {

  &:focus {
    outline: 2px solid $color-focus;
    outline-offset: 4px;
  }
}

address {
  font-size: 1.125em;
  text-align: center;
  font-style: normal;
}

hr:not([class]) {
  display: block;
  width: 100%;
  height: 0;
  border: 0;
  border-bottom: 1px solid;
  opacity: 0.32;
  background: none;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

[hidden] {
  display: none !important; // sass-lint:disable-line no-important
}
