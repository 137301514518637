/* -----------------------------------------------
   #BUNDLE
   ----------------------------------------------- */

/// Spacing to add between each individual bundle
/// item.
///
/// @type Number

$bundle-gutter: 3em !default;



/**
 * Bundle Object
 *
 * The bundle object causes any number of elements
 * to automatically fill an equal, fluid width of
 * their parent.
 */

.o-bundle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.5 * $bundle-gutter;

  &__item {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 20em;
    padding: 0.5 * $bundle-gutter;
  }
}
