/* -----------------------------------------------
   #TAGS
   ----------------------------------------------- */

/// Text color of tag labels.
///
/// @type Color

$tag-text-color: $color-text-secondary !default;



/**
 * Tags
 *
 * Tags are labels which hold small amounts of
 * information.
 */

.c-tag {
  display: inline-block;
  vertical-align: middle;
  padding: 0.25em 0.5em;
  border: 1px solid currentColor;
  border-radius: 1em;
  color: $tag-text-color;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
