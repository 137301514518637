/* -----------------------------------------------
   #MENU
   ----------------------------------------------- */

/// The width of the hamburger icon.
///
/// @type Number

$menu-icon-width: 40px !default;

/// The stroke width of the each individual line of
// the hamburger icon.
///
/// @type Number

$menu-icon-thickness: 4px !default;

/// The spacing between each individual/ line of the
/// hamburger icon.
///
/// @type Number

$menu-icon-spacing: 6px !default;

/// The color of the hamburger icon.
///
/// @type Color

$menu-icon-color: $color-sherpa-blue !default;

/// The color of the hamburger icon when it is
/// active.
///
/// @type Color

$menu-icon-active: $color-highlight !default;


/**
 * Menu Icon
 *
 * A menu icon can be used to display an icon representation
 * for a menu and its current state.
 *
 * @example markup
 *   <span class="c-menu-icon">
 *     <span class="c-menu-icon__inner"></span>
 *   </span>
 */

.c-menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: $menu-icon-width;
  height: (3 * $menu-icon-thickness) + (2 * $menu-icon-spacing);
  color: $menu-icon-color;
  transition: color $duration-promptly linear;

  .is-active & {
    color: $menu-icon-active;
  }

  &__inner {
    top: 50%;
    margin-top: -0.5 * $menu-icon-thickness;
    transition: transform 0.22s $ease-in-cubic;

    .is-active & {
      transform: rotate(225deg);
      transition: transform 0.22s 0.12s $ease-out-cubic;
    }

    &,
    &::before,
    &::after {
      position: absolute;
      display: block;
      width: 100%;
      height: $menu-icon-thickness;
      background-color: currentColor;
    }

    &::before,
    &::after {
      content: '';
    }

    &::before {
      top: -$menu-icon-spacing - $menu-icon-thickness;
      transition:
        top 0.1s 0.25s ease-in,
        opacity 0.1s ease-in;

      .is-active & {
        top: 0;
        opacity: 0;
        transition:
          top 0.1s ease-out,
          opacity 0.1s 0.12s ease-out;
      }
    }

    &::after {
      bottom: -$menu-icon-spacing - $menu-icon-thickness;
      transition:
        bottom 0.1s 0.25s ease-in,
        transform 0.22s $ease-in-cubic;

      .is-active & {
        bottom: 0;
        transform: rotate(-90deg);
        transition:
          bottom 0.1s ease-out,
          transform 0.22s 0.12s $ease-out-cubic;
      }
    }
  }
}
