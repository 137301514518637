/* ----------------------------------------------
   #CARDS
   ---------------------------------------------- */

/**
 * Card Component
 *
 * A card is a self-contained piece of information
 * usually displayed together with a preview image.
 *
 * @example markup
 *   <article class="c-card">
 *     <h2 class="c-card__title">
 *       <a href="#">Card Title</a>
 *     </h2>
 *     <p>Lorem …</p>
 *     <div class="c-card__figure">
 *       <img src="…" alt="">
 *     </div>
 *   </article>
 */

.c-card {
  display: flex;
  flex-direction: column;
  padding: 10%;
  margin-bottom: 2em;
  border: 1px solid tint($color-text-primary, 90%);

  &__header {
    display: flex;
    justify-content: center;
    order: -2;
    padding: 0.5rem 12%;
    margin: -6% -12% 12%;
    text-align: center;
    border-bottom: 1px solid tint($color-text-primary, 90%);

    &-icon {
      margin-left: auto;
    }
  }

  &__meta {
    margin-top: -0.75rem;
    margin-bottom: 1rem;
    opacity: 0.875;
  }

  &__figure {
    order: -1;
    margin-bottom: 1rem;
  }
}



/**
 * Color Variations
 *
 * A set of modifiers used to customize the color of
 * a card component.
 */

.c-card--tint {
  border-color: shade($color-primary, 2%);
  color: $color-text-primary;
  background-color: $color-primary;

  > .c-card__header {
    border-bottom-width: 2px;
    border-bottom-color: shade($color-primary, 5%);
  }
}
