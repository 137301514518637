/* -----------------------------------------------
   #CALLOUTS
   ----------------------------------------------- */

/**
 * Callout
 *
 * @example markup
 *   <div class="c-callout">
 *     …
 *   </div>
 */

.c-callout {
  display: block;
  padding: 1rem;
  padding-right: 0rem;
  border-left: 0.5rem solid $color-primary;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-family: $font-family-sans;
  font-size: $font-size-h4;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.1;
}
