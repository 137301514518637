// -----------------------------------------------
// #TYPOGRAPHY
// -----------------------------------------------

/// A set of variables to control the initial
/// font size of page elements.
///
/// @type Number

$base-font-size:   16px;
$base-line-height: 1.5;



/// A typographic scale.
///
/// @type Number

$font-size-h1: 108px;
$font-size-h2: 54px;
$font-size-h3: 36px;
$font-size-h4: 27px;

$font-size-xl: 54px;
$font-size-lg: 24px;
$font-size-sm: 14px;
$font-size-xs: 12px;



/// A list of font families used within the theme.
///
/// @type List

$font-family-serif:      Karma, serif;
$font-family-sans:       Cervo Neue, sans-serif;

$font-family-primary:    $font-family-serif;
$font-family-secondary:  $font-family-sans;
