/* -----------------------------------------------
   #POSTS
   ----------------------------------------------- */

/**
 * Post List
 *
 * A list of generic posts.
 *
 * @example markup
 *   <ol class="c-post-list" reversed start="3">
 *     <li class="c-post-list__item">
 *       <article class="c-post">…</article>
 *     </li>
 *     <li class="c-post-list__item">
 *       <article class="c-post">…</article>
 *     </li>
 *     <li class="c-post-list__item">
 *       <article class="c-post">…</article>
 *     </li>
 *   </div>
 */

.c-post-list {
  list-style: none;
  margin-top: 3em;
  margin-bottom: 3em;
}



/**
 * Post Component
 *
 * A simple component used for short, date-stamped
 * content excerpts.
 *
 * @example markup
 *   <div class="c-post">
 *     <h1 class="c-post__title">
 *       Hello World!
 *     </h1>
 *     <div class="c-post__excerpt">
 *        …
 *     </div>
 *     <a href="…" class="c-post__thumb">
 *       <img src="…" alt=""/>
 *     </a>
 *   </div>
 */

.c-post {
  display: block;

  & + & {
    margin-top: 2rem;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__meta {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    color: $color-text-secondary;
    font-size: $font-size-sm;
  }

  &__thumb {
    margin-bottom: 1rem;
    order: -1;
    box-shadow: 0 0 3px rgba($color-shadow, 0.1);

    > img {
      width: 100%;
    }
  }
}



/**
 * Post Teaser
 *
 * A short preview of a post.
 *
 * @example markup
 *   <div class="c-post">
 *     <div class="c-post__body">
 *       <h1 class="c-post__title">
 *         Hello World!
 *       </h1>
 *       <div class="c-post__excerpt">
 *          …
 *       </div>
 *     </div>
 *     <a href="…" class="c-post__thumb">
 *       <img src="…" alt=""/>
 *     </a>
 *   </div>
 */

.c-post--teaser {
  display: flex;
  flex-direction: column;

  @media (min-width: #{$breakpoint-sm}) {
    flex-direction: row;
    align-items: center;

    .c-post__body,
    .c-post__thumb {
      flex-basis: 50%;
    }

    .c-post__thumb {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
}
