/* -----------------------------------------------
   #HOME
   ----------------------------------------------- */

/**
 * Front Page Scope
 *
 * Create a new styling context for elements on the
 * front-page.
 */

.s-home {

  .c-header {

    &__brand {
      animation: breathe 6s ease-out infinite;

      &:hover,
      &:focus {
        animation-play-state: paused;
      }

      @keyframes breathe {
        0% { transform: translateX(-50%) scale(0.92); }
        25% { transform: translateX(-50%) scale(1); }
        60% { transform: translateX(-50%) scale(0.92); }
        100% { transform: translateX(-50%) scale(0.92); }
      }

      @media (min-width: #{$breakpoint-xl}) {
        top: 1em;
        left: 50%;
        width: auto;
        transform: translateX(-50%);
      }
    }
  }

  .o-post-list {

    @media
      (min-width: #{$breakpoint-sm}) and
      (max-width: #{$breakpoint-lg}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;
    }
  }
}
