/* -----------------------------------------------
   #TRUMPS
   ----------------------------------------------- */

// sass-lint:disable no-important

.u-clipped {
  overflow: hidden !important;
}

.u-invisible {
  visibility: hidden !important;
}

.u-highlighted {
  color: $color-primary;
}

.u-paddingless {
  padding: 0 !important;
}

.u-borderless {
  border: 0 !important;
}

.u-marginless {
  margin: 0 !important;
}

.u-radiusless {
  border-radius: 0 !important;
}

.u-shadowless {
  box-shadow: none !important;
}

