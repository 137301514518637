/* ------------------------------------
   #ICONS
   ------------------------------------ */

/**
 * Icon Object
 *
 * The icon object normalizes the appearance of
 * svg icons. SVG icons are generally embedded
 * via a generated icon sprite and can be used
 * by referencing the icon identifier.
 *
 * @example markup
 *  <svg class="o-icon o-icon--mail">
 *    <use xlink:href="#icon-mail"></use>
 *  </svg>
 */

 .o-icon {
  position: relative;
  top: -0.125em;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  transition: stroke $duration-promptly linear;

  &--sm {
    font-size: 0.75em;
  }

  &--lg {
    font-size: 2em;
  }

  &--left {
    margin-right: 0.5em;
  }

  &--right {
    margin-right: 0.5em;
  }
}
