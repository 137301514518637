/* -----------------------------------------------
   #AUTHORS
   ----------------------------------------------- */

/**
 * Author
 *
 * The author component is typically used to
 * display a user profile as a picture with a
 * short introduction text.
 *
 * @example markup
 *   <div class="c-avatar">
 *     <span class="c-avatar__img">
 *       <img src="…" alt=""/>
 *     </span>
 *     <strong class="c-avatar__name">
 *       Max Musterfrau
 *     </strong>
 *     <div class="c-avatar__bio>…</div>
 *   </div>
 */

.c-author {
  display: block;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &__img {
    float: left;
    position: relative;
    overflow: hidden;
    display: block;
    width: 40%;
    height: 0;
    padding-top: 40%;
    border: 1px solid $color-gray-67;
    border-radius: 50%;
    margin-right: 1em;
    margin-bottom: 1em;
    shape-outside: circle();

    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__name {
    display: block;
    margin-bottom: 0.5rem;
    font-family: $font-family-sans;
    font-size: $font-size-lg;
    font-weight: bold;
  }

  &__bio {
    font-size: $font-size-sm;
  }
}
