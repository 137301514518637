/* -----------------------------------------------
   #IMAGES
   ----------------------------------------------- */

/**
 * Scale images with the dimensions of the parent
 * element, but keep the aspect ratio of the image.
 */

.u-img-fluid {
  max-width: 100%;
  height: auto;
}

/**
 * Stretch an image to fit the dimensions of a
 * parent container.
 */

.u-img-fit {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/**
 * Apply a circular border radius to an image
 * element. The ratio of the image should be
 * 1by1.
 */

.u-img-circular {
  border-radius: 50%;
}

/**
 * A placeholder element for missing post
 * thumbnails.
 */

.u-img-placeholder {
  display: block;
  width: 100%;
  padding-top: percentage(2/3);
  background-image: $gradient-primary;
}

/**
 * Hide lazy loaded images if JavaScript is
 * disabled.
 */

.no-js .js-lazy-img {
  display: none;
}
