/* -----------------------------------------------
   #SPENDINO
   ----------------------------------------------- */

/**
 * Spendino Widget
 *
 * @markup
 *   <iframe id="spendinoWidget-1234">…</iframe>
 */

[id^="spendinoWidget"],
[id^="spendinoForm"] {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

[id^="spendinoOverlay_"] {
  background-color: $color-highlight !important;
}

[id^="spendinoWidget"] {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  background-color: transparent !important;
}
