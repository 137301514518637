/* -----------------------------------------------
   #HEADER
   ----------------------------------------------- */

/// Maximum width of the page header.
///
/// @type Number

$header-width: $container-width !default;

/// Text color of the header element.
///
/// @type Color

$header-text-color: $color-text-primary !default;

/// Text color of the header element.
///
/// @type Color

$header-border-color: $color-sherpa-blue !default;

/// Background color of the header element.
///
/// @type Color

$header-background-color: $color-highlight !default;

/// The smallest size of the brand logo.
///
/// @type Number

$header-brand-sm: 192px;

/// The medium size of the brand logo.
///
/// @type Number

$header-brand-lg: 288px;

/// The largest size of the brand logo.
///
/// @type Number

$header-brand-xl: 384px;



/**
 * Page Header
 *
 * A container typically holding the site logo and
 * navigation.
 *
 * @example markup
 *   <header class="c-header">
 *     <div class="c-header__brand">
 *       <svg>…</svg>
 *     </div>
 *     <div class="c-header__menu">
 *       <nav>…</nav>
 *     </div>
 *   </header>
 */

.c-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;

  &__brand {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: $layer-branding;
    transform: translateX(-50%);

    @media (min-width: #{$breakpoint-lg}) {
      top: 2em;
    }

    @media (min-width: #{$breakpoint-xl}) {
      width: $header-brand-lg;
      top: -2em;
      left: 3vw;
      transform: none;
    }

    > img {
      width: $header-brand-sm;
      max-width: 100%;
      height: auto;
      @include fluid-property(width, $breakpoint-sm, $breakpoint-xl, $header-brand-sm, $header-brand-xl);
    }
  }

  &__menu {
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: $layer-branding + 1;
    padding-left: 1.5em;
    padding-right: 1.5em;
    color: $header-text-color;
    background-color: $header-background-color;
    @include elevate(5);

    @media (max-width: #{$breakpoint-lg}) {
      width: 100%;
      border-top: 1em solid $header-border-color;
    }
  }
}
