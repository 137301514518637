// -----------------------------------------------
// #LAYERS
// -----------------------------------------------

/// Maintain those nasty z indices in your
/// project via global variables and save you
/// from headaches.
///
/// @type Number

$layer-trump:       100;
$layer-modal:       75;
$layer-overlay:     50;
$layer-menu:        25;
$layer-control:     15;
$layer-branding:    10;
$layer-above:        1;
$layer-default:      0;
$layer-below:       -5;
$layer-background: -10;
