@charset "UTF-8";
/*! Lungenstiftung Bremen | 2019 */
/* -----------------------------------------------
   #FONTS
   ----------------------------------------------- */
@font-face {
  font-family: Cervo Neue;
  src: url("../fonts/cervo-neue-regular.woff2") format("woff2"), url("../fonts/cervo-neue-regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: Cervo Neue;
  src: url("../fonts/cervo-neue-bold.woff2") format("woff2"), url("../fonts/cervo-neue-bold.woff") format("woff");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: Karma;
  src: url("../fonts/karma-regular.woff2") format("woff2"), url("../fonts/karma-regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: Karma;
  src: url("../fonts/karma-bold.woff2") format("woff2"), url("../fonts/karma-bold.woff") format("woff");
  font-display: swap;
  font-weight: 700;
}
/* -----------------------------------------------
   #BOX-SIZING
   ----------------------------------------------- */
/**
 * Make CSS Layouts easier by opting for
 * a different box model than the default
 * content-box.
 *
 * @see @link https://css-tricks.com/?p=175473|Inheriting box-sizing Probably Slightly Better Best-Practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* -----------------------------------------------
   #REBOOT
   ----------------------------------------------- */
/**
 * Remove margins and paddings from elements of
 * any type using the univeral selector. Set
 * some opinionated defaults on other elements.
 *
 * @link https://css-tricks.com/?p=6 Reset all Margin & Padding
 */
* {
  margin: 0;
  padding: 0;
}

img,
svg {
  vertical-align: middle;
}

img {
  border-style: none;
  font-style: italic;
}

ul,
ol {
  list-style: none;
}

main {
  min-height: 100vh;
}

details,
summary {
  display: block;
}

strong {
  font-weight: bold;
}

small {
  font-size: 1em;
}

fieldset {
  min-width: 0;
  border: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

button {
  border: 0;
  color: inherit;
  font: inherit;
  text-transform: none;
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

a:focus,
button:focus {
  outline: 2px solid #3273dc;
  outline-offset: 4px;
}

address {
  font-size: 1.125em;
  text-align: center;
  font-style: normal;
}

hr:not([class]) {
  display: block;
  width: 100%;
  height: 0;
  border: 0;
  border-bottom: 1px solid;
  opacity: 0.32;
  background: none;
}
hr:not([class]):not(:last-child) {
  margin-bottom: 2rem;
}

[hidden] {
  display: none !important;
}

/* -----------------------------------------------
   #DOCUMENT
   ----------------------------------------------- */
/**
 * Styling of the document root. Establishes the
 * vertical baseline grid and customizes the
 * default `font-size` and `line-height` used.
 */
html {
  height: 100%;
  color: #004E5B;
  font-family: Karma, serif;
  font-size: 100%;
  line-height: 1.5;
  background-color: #FFF;
}

body {
  position: relative;
  min-height: 100%;
  font-size: 1em;
}
@media (min-width: 24em) {
  body {
    font-size: calc(1em + 0.125 * (100vw - 24em) / 44);
  }
}
@media (min-width: 68em) {
  body {
    font-size: 1.125em;
  }
}

/* ----------------------------------------------
   #CONTAINER
   ---------------------------------------------- */
/**
 * Content Container
 *
 * A content container helps to limit the
 * maximum width of a page element and avoids
 * repititon of code.
 *
 * @example markup
 *  <div class="o-container">
 *    …
 *  </div>
 */
.o-container {
  width: 100%;
  max-width: 1280px;
  padding-left: 1em;
  padding-right: 1em;
  margin-left: auto;
  margin-right: auto;
}
.o-container--narrow {
  max-width: 768px;
}
.o-container--wide {
  max-width: 1632px;
}

/* -----------------------------------------------
   #LAYOUT
   ----------------------------------------------- */
/**
 * Layout Grid
 *
 * The layout grid separates the main content from
 * complementary contents.
 *
 * @example markup
 *   <main class="o-layout">
 *     <div class="o-layout__main>
 *       …
 *     </div>
 *     <div class="o-layout__aside>
 *       …
 *     </div>
 *   </main>
 */
.o-layout {
  display: flex;
  margin-top: 3em;
  margin-bottom: 3em;
}
@media (max-width: 1088px) {
  .o-layout {
    flex-direction: column;
  }
  .o-layout__aside {
    padding-top: 3em;
  }
}
@media (min-width: 1088px) {
  .o-layout {
    flex-direction: row;
  }
  .o-layout__main {
    flex-basis: 66.6666666667%;
  }
  .o-layout__aside {
    flex-basis: 33.3333333333%;
    padding-left: 3em;
  }
}

/* -----------------------------------------------
   #GRID
   ----------------------------------------------- */
/**
 * Grid Layout
 *
 * A simple grid system used to arrange content on
 * the page.
 *
 * @example markup
 *   <div class="o-grid">
 *      <div class="o-grid__text">…</div>
 *      <div class="o-grid__media">…</div>
 *      <div class="o-grid__text">…</div>
 *      <div class="o-grid__media">…</div>
 *   </div>
 */
.o-grid {
  display: flex;
  flex-direction: column;
}
.o-grid > .o-grid__media {
  position: relative;
  max-height: 25vmax;
}
.o-grid > .o-grid__text {
  padding: 3%;
}

/**
 * Two Column Grid Layout
 *
 * Separate text and media content into two separate
 * columns for larger media breakpoints.
 */
@media (min-width: 1088px) {
  .o-grid {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .o-grid > .o-grid__media,
  .o-grid > .o-grid__text {
    flex-basis: 50%;
    margin-bottom: 3em;
  }
  .o-grid > .o-grid__text {
    padding: 5%;
  }
  .o-grid > .o-grid__media {
    max-height: none;
    min-height: 20vw;
  }
  .o-grid > .o-grid__media > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .o-grid--flush > .o-grid__media,
  .o-grid--flush > .o-grid__text {
    margin-bottom: 0;
  }
}
/**
 * Advanced Grid Layout
 *
 * Improve the readability of text in the grid, if
 * the client supports the CSS grid layout module.
 */
@supports (display: grid) {
  @media (min-width: 68em) {
    .o-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: dense;
      grid-row-gap: 3em;
    }
    .o-grid--flush {
      grid-row-gap: 0;
    }
    .o-grid > .o-grid__media {
      grid-column: 2/span 1;
    }
    .o-grid > .o-grid__text {
      grid-column: 1/span 1;
    }
    .o-grid > .o-grid__media,
    .o-grid > .o-grid__text {
      margin-bottom: 0;
    }
    .o-grid > .o-grid__media:nth-child(4n+1),
    .o-grid > .o-grid__text:nth-child(4n+1) {
      grid-column-start: 2;
    }
    .o-grid > .o-grid__media:nth-child(4n+2),
    .o-grid > .o-grid__text:nth-child(4n+2) {
      grid-column-start: 1;
    }
  }
  @media (min-width: 102em) {
    .o-grid {
      grid-template-columns: 1fr minmax(auto, 45.3333333333em) minmax(auto, 45.3333333333em) 1fr;
    }
    .o-grid > .o-grid__media {
      grid-column: 3/span 2;
    }
    .o-grid > .o-grid__text {
      grid-column: 2/span 1;
    }
    .o-grid > .o-grid__media:nth-child(4n+1),
    .o-grid > .o-grid__text:nth-child(4n+1) {
      grid-column-start: 3;
    }
    .o-grid > .o-grid__media:nth-child(4n+2),
    .o-grid > .o-grid__text:nth-child(4n+2) {
      grid-column-start: 1;
    }
  }
}
/* -----------------------------------------------
   #BUNDLE
   ----------------------------------------------- */
/**
 * Bundle Object
 *
 * The bundle object causes any number of elements
 * to automatically fill an equal, fluid width of
 * their parent.
 */
.o-bundle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1.5em;
}
.o-bundle__item {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 20em;
  padding: 1.5em;
}

/* ------------------------------------
   #ICONS
   ------------------------------------ */
/**
 * Icon Object
 *
 * The icon object normalizes the appearance of
 * svg icons. SVG icons are generally embedded
 * via a generated icon sprite and can be used
 * by referencing the icon identifier.
 *
 * @example markup
 *  <svg class="o-icon o-icon--mail">
 *    <use xlink:href="#icon-mail"></use>
 *  </svg>
 */
.o-icon {
  position: relative;
  top: -0.125em;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  transition: stroke 0.15s linear;
}
.o-icon--sm {
  font-size: 0.75em;
}
.o-icon--lg {
  font-size: 2em;
}
.o-icon--left {
  margin-right: 0.5em;
}
.o-icon--right {
  margin-right: 0.5em;
}

/* -----------------------------------------------
   #LIST-BARE
   ----------------------------------------------- */
/**
 * Inline Bare
 *
 * The inline-list object remoes list-like
 * appearance from elements by removing bullets and
 * indentation.
 *
 * @example markup
 *   <dl class="o-list-bare">
 *     <dt class="o-list-bare__item">…</li>
 *     <dd class="o-list-bare__item">…</li>
 *     <dt class="o-list-bare__item">…</li>
 *     <dd class="o-list-bare__item">…</li>
 *   </dl>
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}
.o-list-bare__item {
  margin-left: 0;
}

/* -----------------------------------------------
   #LIST-INLINE
   ----------------------------------------------- */
/**
 * Inline List
 *
 * The inline-list object simply displays a list of
 * items in a row.
 *
 * @example markup
 *   <ul class="o-list-inline">
 *     <li class="o-list-inline__item">…</li>
 *     <li class="o-list-inline__item">…</li>
 *     <li class="o-list-inline__item">…</li>
 *   </ul>
 */
.o-list-inline {
  margin-left: -1em;
}
.o-list-inline__item {
  display: inline-block;
  margin-left: 1em;
}

/**
 * Vertical alignment modifiers
 *
 * A set of modifiers used to change the alignment
 * of list items.
 */
/**
 * Align all list items to the middles of each
 * other.
 */
.o-list-inline--middle > .o-list-inline__item {
  vertical-align: middle;
}

/**
 * Align all list items to the bottoms of each
 * other.
 */
.o-list-inline--bottom > .o-list-inline__item {
  vertical-align: bottom;
}

/* -----------------------------------------------
   #HEADER
   ----------------------------------------------- */
/**
 * Page Header
 *
 * A container typically holding the site logo and
 * navigation.
 *
 * @example markup
 *   <header class="c-header">
 *     <div class="c-header__brand">
 *       <svg>…</svg>
 *     </div>
 *     <div class="c-header__menu">
 *       <nav>…</nav>
 *     </div>
 *   </header>
 */
.c-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
}
.c-header__brand {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
}
@media (min-width: 1088px) {
  .c-header__brand {
    top: 2em;
  }
}
@media (min-width: 1632px) {
  .c-header__brand {
    width: 288px;
    top: -2em;
    left: 3vw;
    transform: none;
  }
}
.c-header__brand > img {
  width: 192px;
  max-width: 100%;
  height: auto;
  width: 12em;
}
@media (min-width: 48em) {
  .c-header__brand > img {
    width: calc(12em + 12 * (100vw - 48em) / 54);
  }
}
@media (min-width: 102em) {
  .c-header__brand > img {
    width: 24em;
  }
}
.c-header__menu {
  position: relative;
  display: inline-block;
  vertical-align: top;
  z-index: 11;
  padding-left: 1.5em;
  padding-right: 1.5em;
  color: #004E5B;
  background-color: #FFF;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.07), 0 15px 12px rgba(0, 0, 0, 0.05);
}
@media (max-width: 1088px) {
  .c-header__menu {
    width: 100%;
    border-top: 1em solid #004E5B;
  }
}

/* -----------------------------------------------
   #FOOTER
   ----------------------------------------------- */
/**
 * Page Footer
 *
 * A website’s footer is an area located at the
 * bottom of every page on a website, below the main
 * content.
 *
 * @example markup
 *   <footer class="c-footer">
 *     <div class="c-footer__item">
 *       …
 *     </div>
 *     <div class="c-footer__item">
 *       …
 *     </div>
 *   </footer>
 */
.c-footer {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
}
.c-footer__item {
  padding-top: 1em;
  padding-bottom: 1em;
}
.c-footer__item:last-child {
  color: #FFF;
  text-align: right;
  background-color: #004E5B;
}
@media (max-width: 768px) {
  .c-footer__item:last-child {
    margin-top: 3em;
  }
}

/* -----------------------------------------------
   #NAV
   ----------------------------------------------- */
/**
 * Site Navigation
 *
 * The site navigation contains links to the various
 * pages of a website.
 *
 * @example markup
 *   <nav class="c-site-nav">
 *     <ul class="c-site-nav__menu">
 *       <li class="c-site-nav__item">
 *         <a class="c-site-nav__link" href="…">…</a>
 *       </li>
 *       <li class="c-site-nav__item">
 *         <a class="c-site-nav__link" href="…">…</a>
 *       </li>
 *     </ul>
 *   </nav>
 */
.c-site-nav {
  position: relative;
  width: 100%;
  /**
   * Mobile Site Navigation
   *
   * Collapse the navigation for mobile devices and
   * display navigation entries in an off-canvas
   * menu.
   */
}
.c-site-nav__toggle-button {
  display: none;
  position: fixed;
  top: 2em;
  right: 1em;
  z-index: 26;
  padding: 0.5em;
  cursor: pointer;
}
.c-site-nav__toggle-button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.9);
  transition: transform 0.15s ease;
}
.c-site-nav__toggle-button[aria-expanded=true] {
  color: #FFF;
  background-color: #004E5B;
}
.c-site-nav__toggle-button[aria-expanded=true]::after {
  transform: scale(0);
}
.c-site-nav__menu {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -1em;
}
.c-site-nav__item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
}
.c-site-nav__link {
  display: inline-block;
  vertical-align: middle;
  padding: 0.75rem 1rem;
  font-family: Cervo Neue, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 0.15s linear;
}
.c-site-nav__link:hover, .c-site-nav__link:focus {
  color: #FD3E5C;
}
.c-site-nav__link:active {
  outline: 0;
}
.c-site-nav__link--is-active {
  padding-top: 2rem;
  margin-bottom: -2rem;
  color: #004E5B;
  background-color: #FFF;
}
@media (max-width: 1088px) {
  .c-site-nav__toggle-button {
    display: inline-block;
  }
  .c-site-nav__menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 25;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    opacity: 0;
    visibility: hidden;
    color: #FFF;
    background-color: #004E5B;
    transition: opacity 0.2s 0.3s linear, visibility 0.2s 0.3s linear;
  }
  .is-menu-open .c-site-nav__menu {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear, visibility 0.2s linear;
  }
  .c-site-nav__item {
    display: block;
    width: 100%;
    margin-left: 0;
  }
  .c-site-nav__link {
    position: relative;
    font-size: 48px;
    color: #FFF;
    background-color: transparent;
  }
  .c-site-nav__link--is-active {
    padding-top: 0.75rem;
    margin-bottom: 0;
  }
  .c-site-nav__link--is-active::after {
    content: "";
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 0;
    display: block;
    height: 3px;
    margin-bottom: 0.375em;
    background-color: #FD3E5C;
    transition: transform 0.15s ease;
  }
  .c-site-nav__link--is-active:focus::after, .c-site-nav__link--is-active:hover::after {
    transform: scaleX(1.05);
  }
}

/**
 * Navigation
 *
 * A generic navigation menu contains links to the
 * a group of pages.
 *
 * @example markup
 *   <nav class="c-nav">
 *     <ul class="c-nav__menu">
 *       <li class="c-nav__item">
 *         <a class="c-nav__link" href="…">…</a>
 *       </li>
 *       <li class="c-nav__item">
 *         <a class="c-nav__link" href="…">…</a>
 *       </li>
 *     </ul>
 *   </nav>
 */
.c-nav {
  position: relative;
  width: 100%;
}
.c-nav__menu {
  display: flex;
  align-items: baseline;
  margin-top: -1em;
  margin-left: -1em;
}
.c-nav__item {
  display: inline-block;
  vertical-align: middle;
  margin-top: 1em;
  margin-left: 1em;
}
.c-nav__link {
  display: block;
  font-family: Cervo Neue, sans-serif;
  font-size: 1.125em;
  line-height: 1.2;
  transition: color 0.15s linear;
}
.c-nav__link:hover, .c-nav__link:focus {
  color: #FD3E5C;
}
.c-nav__link:active {
  outline: 0;
}
.c-nav__link--strong {
  padding: 3rem 2rem 2rem;
  margin-top: -3rem;
  margin-bottom: -1rem;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #FFF;
  background-color: #FD3E5C;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06), 0 6px 6px rgba(0, 0, 0, 0.07);
}
.c-nav__link--strong:hover, .c-nav__link--strong:focus {
  color: rgb(254.5, 206.75, 214.25);
}

/**
 * Customize element order
 *
 * Customize the order navigation items are
 * rendered.
 */
.c-nav--center > .c-nav__menu {
  justify-content: center;
}

.c-nav--right > .c-nav__menu {
  justify-content: flex-end;
}

.c-nav--reverse > .c-nav__menu {
  flex-direction: row-reverse;
}

/* -----------------------------------------------
   #MENU
   ----------------------------------------------- */
/**
 * Menu Icon
 *
 * A menu icon can be used to display an icon representation
 * for a menu and its current state.
 *
 * @example markup
 *   <span class="c-menu-icon">
 *     <span class="c-menu-icon__inner"></span>
 *   </span>
 */
.c-menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 24px;
  color: #004E5B;
  transition: color 0.15s linear;
}
.is-active .c-menu-icon {
  color: #FFF;
}
.c-menu-icon__inner {
  top: 50%;
  margin-top: -2px;
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.is-active .c-menu-icon__inner {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-menu-icon__inner, .c-menu-icon__inner::before, .c-menu-icon__inner::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  background-color: currentColor;
}
.c-menu-icon__inner::before, .c-menu-icon__inner::after {
  content: "";
}
.c-menu-icon__inner::before {
  top: -10px;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.is-active .c-menu-icon__inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.c-menu-icon__inner::after {
  bottom: -10px;
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.is-active .c-menu-icon__inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* -----------------------------------------------
   #SKIP
   ----------------------------------------------- */
/**
 * Skip Links
 *
 * Skip links are internal page links which aid
 * navigation around the current page. They are
 * mainly used by screen reader users for bypassing
 * repetitive content.
 *
 * @example markup
 *   <a href="#content" class="c-skip-link">
 *     Skip to main content
 *   </a>
 */
.c-skip-link {
  font-family: Cervo Neue, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: translate(-50%, -100%);
  transition: transform 0.1s;
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
}
.c-skip-link:active, .c-skip-link:focus {
  position: absolute;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}
.c-skip-link:active, .c-skip-link:focus {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 15;
  padding: 2.75em 1em 0.75em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #FFF;
  background-color: #FD3E5C;
  transform: translate(-50%, 0);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.07), 0 15px 12px rgba(0, 0, 0, 0.05);
}

/* -----------------------------------------------
   #HEADER
   ----------------------------------------------- */
/**
 * Hero Banner
 *
 * The hero component is an imposing banner to
 * showcase something.
 *
 * @example markup
 *   <header class="c-hero">
 *     <h1 class="c-hero__title">
 *       Hello World!
 *     </h1>
 *     <img src="…" class="c-hero__cover-image" alt=""/>
 *   </header>
 */
.c-hero {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  padding-top: 192px;
  padding-bottom: 2em;
  margin-bottom: 3em;
  color: #9CDFD9;
  text-align: center;
  background-color: #9CDFD9;
  background-image: linear-gradient(15deg, #343377, #a63c60 50%, #d87476 70%, #FBB796 85%);
}
@media (min-height: 768px), (min-width: 768px) {
  .c-hero {
    padding-top: 25vmax;
  }
}
.c-hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%;
  max-height: 128px;
  background: url("../images/bg-header.jpg") top center/cover no-repeat;
}
@media (min-width: 1088px), (min-device-pixel-ratio: 1.25) {
  .c-hero::after {
    background-image: url("../images/bg-header@2x.jpg");
  }
}
.c-hero__title {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1632px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
  color: #9CDFD9;
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.18);
}
.c-hero__cover-image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/**
 * Size Variations
 *
 * The hero component is an imposing banner to
 * showcase something.
 */
.c-hero--invincible::after {
  display: none;
}
.c-hero--invincible .c-hero__title {
  max-width: 64rem;
  color: #FFF;
  line-height: 0.9;
  font-size: 3.375em;
}
@media (min-width: 24em) {
  .c-hero--invincible .c-hero__title {
    font-size: calc(3.375em + 8.625 * (100vw - 24em) / 78);
  }
}
@media (min-width: 102em) {
  .c-hero--invincible .c-hero__title {
    font-size: 12em;
  }
}

/* -----------------------------------------------
   #LINKS
   ----------------------------------------------- */
/**
 * Link Styles
 *
 * By default links have no styling applied.
 * Use this component to emphasize that a
 * text element is a link to another document.
 */
.c-link {
  color: #FD3E5C;
  transition: color 0.15s linear;
}
.c-link:hover, .c-link:focus {
  color: rgb(253.4, 100.6, 124.6);
}
.c-link:active {
  outline: 0;
  color: rgb(227.7, 55.8, 82.8);
}

/* ----------------------------------------------
   #CARDS
   ---------------------------------------------- */
/**
 * Card Component
 *
 * A card is a self-contained piece of information
 * usually displayed together with a preview image.
 *
 * @example markup
 *   <article class="c-card">
 *     <h2 class="c-card__title">
 *       <a href="#">Card Title</a>
 *     </h2>
 *     <p>Lorem …</p>
 *     <div class="c-card__figure">
 *       <img src="…" alt="">
 *     </div>
 *   </article>
 */
.c-card {
  display: flex;
  flex-direction: column;
  padding: 10%;
  margin-bottom: 2em;
  border: 1px solid rgb(229.5, 237.3, 238.6);
}
.c-card__header {
  display: flex;
  justify-content: center;
  order: -2;
  padding: 0.5rem 12%;
  margin: -6% -12% 12%;
  text-align: center;
  border-bottom: 1px solid rgb(229.5, 237.3, 238.6);
}
.c-card__header-icon {
  margin-left: auto;
}
.c-card__meta {
  margin-top: -0.75rem;
  margin-bottom: 1rem;
  opacity: 0.875;
}
.c-card__figure {
  order: -1;
  margin-bottom: 1rem;
}

/**
 * Color Variations
 *
 * A set of modifiers used to customize the color of
 * a card component.
 */
.c-card--tint {
  border-color: rgb(152.88, 218.54, 212.66);
  color: #004E5B;
  background-color: #9CDFD9;
}
.c-card--tint > .c-card__header {
  border-bottom-width: 2px;
  border-bottom-color: rgb(148.2, 211.85, 206.15);
}

/* ----------------------------------------------
   #TILES
   ---------------------------------------------- */
/**
 * Tile Grid
 *
 * A simple container used to group a set of
 * related tiles.
 *
 * @example markup
 *   <div class="c-tile-grid">
 *     <div class="c-tile">…</div>
 *     <div class="c-tile">…</div>
 *   </div>
 */
.c-tile-grid {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  margin-bottom: 3em;
}
@media (min-width: 1088px) {
  .c-tile-grid {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -1em;
  }
}
.c-tile-grid > .c-tile {
  margin-top: 1em;
}
@media (min-width: 1088px) {
  .c-tile-grid > .c-tile {
    flex-basis: calc(50% - 1em);
    margin-left: 1em;
  }
}

/**
 * Tile Component
 *
 * A simple disclosure widget, where the visibility
 * of the inner content can be toggled on click.
 *
 * @example markup
 *   <div class="c-tile">
 *     …
 *   </div>
 */
.c-tile {
  grid-column: span 12;
  position: relative;
  overflow: hidden;
  display: block;
  padding: 3%;
  border: 1px solid rgb(229.5, 237.3, 238.6);
  font-size: 1rem;
}
.c-tile__label {
  float: left;
  position: relative;
  top: -0.375rem;
  margin-bottom: -0.25rem;
  margin-right: 0.75rem;
  font-family: Cervo Neue, sans-serif;
  font-size: 27px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
.c-tile__label::after {
  content: ".";
}

/**
 * Color Variations
 *
 * A set of modifiers used to customize the color of
 * a tile component.
 */
.c-tile--tint {
  border-color: rgb(152.88, 218.54, 212.66);
  color: #004E5B;
  background-color: #9CDFD9;
}
.c-tile--tint > .c-card__header {
  border-bottom-width: 2px;
  border-bottom-color: rgb(148.2, 211.85, 206.15);
}

/* ----------------------------------------------
   #BANNERS
   ---------------------------------------------- */
/**
 * Banner Component
 *
 * A simple, stylized container to divide your page
 * into visual distinctive sections.
 *
 * @example markup
 *   <section class="c-banner">
 *     <header class="c-banner__header">
 *       <h2 class="c-banner__title">
 *         Section Title
 *       </h2>
 *       <img src="…" class="c-header__figure" alt=""/>
 *     </header>
 *     <div class="s-md-content">
 *       <p>Lorem …</p>
 *     </div>
 *   </section>
 */
.c-banner {
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 3em;
  margin-bottom: 3em;
}
.c-banner__header {
  position: relative;
  overflow: hidden;
  padding-top: 15vmax;
  margin-top: -3em;
  margin-bottom: 3em;
  text-align: center;
}
.c-banner__title {
  position: relative;
  z-index: 1;
  padding: 1rem;
  color: #9CDFD9;
  opacity: 0.9;
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.18);
}
.c-banner__figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/**
 * Color Variations
 *
 * A set of modifiers used to customize the color of
 * a banner component.
 */
.c-banner--dark {
  color: #FFF;
  background-color: #004E5B;
}

.c-banner--tint {
  color: #004E5B;
  background-color: #9CDFD9;
}

/* ----------------------------------------------
   #BUTTONS
   ---------------------------------------------- */
/**
 * Button Group
 *
 * A group of buttons, nothing more.
 *
 * @example markup
 *   <div class="c-button-group">
 *     <button type="submit" class="c-button c-button--primary">…</button>
 *     <button type="reset" class="c-button">…</button>
 *   </div>
 */
.c-button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 1px solid rgb(226.95, 226.95, 226.95);
  border-bottom: 1px solid rgb(226.95, 226.95, 226.95);
  margin-top: 2em;
  margin-left: -0.5em;
}
.c-button-group > .c-button {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}

/**
 * Button
 *
 * Buttons are used to perform an action or to
 * invoke an event.
 *
 * @example markup
 *   <a href="…" class="c-button c-button--primary">
 *     …
 *   </a>
 */
.c-button {
  display: inline-block;
  vertical-align: middle;
  padding: calc(0.375em - 1px) calc(1em - 1px);
  border-radius: 0.25em;
  border: 1px solid #9CDFD9;
  color: #FFF;
  font-family: Cervo Neue, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  background-color: #9CDFD9;
  cursor: pointer;
  transition: border-color 0.15s linear, background-color 0.15s linear;
}
.c-button:hover, .c-button:focus {
  border-color: rgb(170.85, 227.8, 222.7);
  background-color: rgb(170.85, 227.8, 222.7);
}
.c-button:active {
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.c-button--primary {
  border-color: #FD3E5C;
  background-color: #FD3E5C;
}
.c-button--primary:hover, .c-button--primary:focus {
  border-color: rgb(253.3, 90.95, 116.45);
  background-color: rgb(253.3, 90.95, 116.45);
}
.c-button--secondary {
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  border-color: transparent;
  color: #004E5B;
  text-transform: none;
  text-shadow: none;
  text-decoration: underline;
  letter-spacing: 0;
  background-color: transparent;
}
.c-button--secondary:hover, .c-button--secondary:focus {
  border-color: transparent;
  background-color: transparent;
}
.c-button--secondary:active {
  box-shadow: none;
  transform: translateY(1px);
}

/* -----------------------------------------------
   #CALLOUTS
   ----------------------------------------------- */
/**
 * Callout
 *
 * @example markup
 *   <div class="c-callout">
 *     …
 *   </div>
 */
.c-callout {
  display: block;
  padding: 1rem;
  padding-right: 0rem;
  border-left: 0.5rem solid #9CDFD9;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-family: Cervo Neue, sans-serif;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.1;
}

/* ----------------------------------------------
   #FORMS
   ---------------------------------------------- */
/**
 * Form
 *
 * A form is a container for multiple input fields.
 *
 * @example markup
 *   <form class="c-form" method="POST" action="">
 *     …
 *   </form>
 */
.c-form {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 48em;
  margin: 3em auto;
  padding: 1em;
}
.c-form > * {
  grid-column: 1/-1;
}
@media (min-width: 54em) {
  .c-form {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.75em;
    padding: 3em;
    border: 1px solid rgb(226.95, 226.95, 226.95);
    border-radius: 0.25em;
  }
  .c-form > [data-width="1/2"] {
    grid-column: span 6;
  }
  .c-form > [data-width="1/3"] {
    grid-column: span 4;
  }
  .c-form > [data-width="2/3"] {
    grid-column: span 8;
  }
}

/**
 * Field
 *
 * A form field is a container for a text label, the
 * actual form control and some optional help.
 *
 * @example markup
 *   <div class="c-field">
 *     <label for="email" class="c-field__label">
 *       E-Mail
 *       <span>
 *         (Pflichtfeld)
 *       </span>
 *     </label>
 *     <input type="email" id="email" name="email" class="c-input" value="" />
 *     <p class="c-field__help">
 *       Please provide a valid email address.
 *     </p>
 *   </div>
 */
.c-field {
  display: block;
  margin-bottom: 0.75em;
}
.c-field__label {
  display: block;
  font-weight: 700;
  margin-bottom: 0.25em;
}
.c-field__label > span {
  color: #FD3E5C;
}
.c-field__help {
  display: block;
  font-size: 14px;
  margin-top: 0.5em;
}
.c-field--is-valid .c-field__help {
  color: #4CAF50;
}
.c-field--is-invalid .c-field__help {
  color: #F5480E;
}
.c-field.🐝 {
  position: absolute;
  left: -999em;
}

/**
 * Text Input
 *
 * Form controls for textual user input.
 *
 * @example markup
 *   <input type="text" value="" class="c-input" />
 */
.c-input {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  border-radius: 0.125em;
  border: 1px solid #dbdbdb;
  color: #004E5B;
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.5;
  background-color: #FFF;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.c-input:hover {
  border-color: rgb(186.15, 186.15, 186.15);
}
.c-input:focus {
  border-color: #3273dc;
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}
.c-input[disabled] {
  color: rgb(170.85, 170.85, 170.85);
  border-color: rgb(235.365, 235.365, 235.365);
  background-color: rgb(240.975, 240.975, 240.975);
  box-shadow: none;
  cursor: not-allowed;
}
.c-input[rows] {
  height: initial;
}
.c-input--is-valid {
  border-color: #4CAF50;
}
.c-input--is-valid:hover {
  border-color: #4CAF50;
}
.c-input--is-valid:focus {
  box-shadow: 0 0 0 0.125em rgba(76, 175, 80, 0.25);
}
.c-input--is-invalid {
  border-color: #F5480E;
}
.c-input--is-invalid:hover {
  border-color: #F5480E;
}
.c-input--is-invalid:focus {
  box-shadow: 0 0 0 0.125em rgba(245, 72, 14, 0.25);
}

/**
 * Checkbox
 *
 * A checkbox allows one or multiple values to be
 * selected from a list of choices
 *
 * @example markup
 *   <label class="c-checkbox">
 *      <input type="checkbox" class="c-checkbox__control">
 *      Remember Password
 *   </label>
 */
.c-checkbox {
  position: relative;
  display: block;
  padding-left: 1.5em;
}
.c-checkbox__control {
  position: absolute;
  left: 0;
  top: 0.375em;
}

/* ----------------------------------------------
   #PAGINATION
   ---------------------------------------------- */
/**
 * Pagination Links
 *
 * A navigation component to jump between individual
 * pages of a paginated post archive.
 *
 * @example markup
 *   <nav class="c-pagination" aria-label="Pagination">
 *     <a href="…" class="c-pagination__previous">Previous Page</a>
 *     <a href="…" class="c-pagination__next">Next Page</a>
 *     <ul class="c-pagination__list">
 *       <li class="c-pagination__item">
 *         <a href="…" class="c-pagination__link">Page 1</a>
 *       </li>
 *       <li class="c-pagination__item">
 *         <a href="…" class="c-pagination__link">Page 2</a>
 *       </li>
 *       <li class="c-pagination__item">
 *         <a href="…" class="c-pagination__link">Page 3</a>
 *       </li>
 *     </ul>
 *   </nav>
 */
.c-pagination {
  margin: -0.25rem;
  font-size: 1rem;
}
.c-pagination, .c-pagination__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.c-pagination__list {
  list-style: none;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  order: 1;
}
.c-pagination__previous, .c-pagination__next, .c-pagination__link {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  height: 2rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin: 0.25rem;
  border: 1px solid rgb(191.25, 210.75, 214);
  border-radius: 0.25rem;
  color: #004E5B;
  font-family: Cervo Neue, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  background-color: rgb(252.03, 254.04, 253.86);
  transition: border-color 0.15s linear;
}
.c-pagination__previous:hover, .c-pagination__previous:focus, .c-pagination__next:hover, .c-pagination__next:focus, .c-pagination__link:hover, .c-pagination__link:focus {
  border-color: rgb(127.5, 166.5, 173);
}
.c-pagination__previous:active, .c-pagination__next:active, .c-pagination__link:active {
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.c-pagination__previous[disabled], .c-pagination__next[disabled], .c-pagination__link[disabled] {
  opacity: 0.5;
  border-color: rgb(226.95, 226.95, 226.95);
  color: rgb(170.85, 170.85, 170.85);
  background-color: rgb(226.95, 226.95, 226.95);
  box-shadow: none;
  cursor: not-allowed;
}
.c-pagination__previous {
  order: 2;
}
.c-pagination__previous::before {
  content: "‹";
  margin-right: 0.25em;
}
.c-pagination__next {
  order: 3;
}
.c-pagination__next::after {
  content: "›";
  margin-left: 0.25em;
}
.c-pagination__link--is-active {
  border-color: rgb(227.7, 55.8, 82.8);
  color: #FFF;
  background-color: #FD3E5C;
}
.c-pagination__link--is-active:hover, .c-pagination__link--is-active:focus {
  border-color: #fd3e5c;
  background-color: rgb(253.3, 90.95, 116.45);
}

/* ----------------------------------------------
   #ACCORDIONS
   ---------------------------------------------- */
/**
 * Accordion Component
 *
 * A simple disclosure widget, where the visibility
 * of the inner content can be toggled on click.
 *
 * @example markup
 *   <details class="c-accordion">
 *     <summary class="c-accordion__summary">
 *       Heading
 *     </summary>
 *     …
 *   </details>
 */
.c-accordion {
  display: block;
  margin-bottom: 1rem;
  color: #004E5B;
  font-family: Cervo Neue, sans-serif;
  background-color: #9CDFD9;
}
.c-accordion > * {
  color: inherit !important;
}
.c-accordion, .c-accordion__summary {
  display: inline-block;
  vertical-align: middle;
  padding: 1rem 1.75rem;
}
.c-accordion__summary {
  margin: -1rem -1.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
[open] .c-accordion__summary {
  display: block;
  margin-bottom: 0;
}
.c-accordion__summary::before {
  content: "";
  position: relative;
  top: -0.0625em;
  display: inline-block;
  vertical-align: middle;
  width: 0.75em;
  height: 0.75em;
  margin-right: 1em;
  border-top: 0.25em solid currentColor;
  border-right: 0.25em solid currentColor;
  transform: rotate(45deg);
  transition: transform 0.15s ease-in-out;
}
[open] .c-accordion__summary::before {
  transform: translateY(-25%) rotate(135deg);
}

/* -----------------------------------------------
   #TAGS
   ----------------------------------------------- */
/**
 * Tags
 *
 * Tags are labels which hold small amounts of
 * information.
 */
.c-tag {
  display: inline-block;
  vertical-align: middle;
  padding: 0.25em 0.5em;
  border: 1px solid currentColor;
  border-radius: 1em;
  color: #798f93;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* -----------------------------------------------
   #POSTS
   ----------------------------------------------- */
/**
 * Post List
 *
 * A list of generic posts.
 *
 * @example markup
 *   <ol class="c-post-list" reversed start="3">
 *     <li class="c-post-list__item">
 *       <article class="c-post">…</article>
 *     </li>
 *     <li class="c-post-list__item">
 *       <article class="c-post">…</article>
 *     </li>
 *     <li class="c-post-list__item">
 *       <article class="c-post">…</article>
 *     </li>
 *   </div>
 */
.c-post-list {
  list-style: none;
  margin-top: 3em;
  margin-bottom: 3em;
}

/**
 * Post Component
 *
 * A simple component used for short, date-stamped
 * content excerpts.
 *
 * @example markup
 *   <div class="c-post">
 *     <h1 class="c-post__title">
 *       Hello World!
 *     </h1>
 *     <div class="c-post__excerpt">
 *        …
 *     </div>
 *     <a href="…" class="c-post__thumb">
 *       <img src="…" alt=""/>
 *     </a>
 *   </div>
 */
.c-post {
  display: block;
}
.c-post + .c-post {
  margin-top: 2rem;
}
.c-post__title {
  margin-bottom: 1rem;
}
.c-post__meta {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: #798f93;
  font-size: 14px;
}
.c-post__thumb {
  margin-bottom: 1rem;
  order: -1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.c-post__thumb > img {
  width: 100%;
}

/**
 * Post Teaser
 *
 * A short preview of a post.
 *
 * @example markup
 *   <div class="c-post">
 *     <div class="c-post__body">
 *       <h1 class="c-post__title">
 *         Hello World!
 *       </h1>
 *       <div class="c-post__excerpt">
 *          …
 *       </div>
 *     </div>
 *     <a href="…" class="c-post__thumb">
 *       <img src="…" alt=""/>
 *     </a>
 *   </div>
 */
.c-post--teaser {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .c-post--teaser {
    flex-direction: row;
    align-items: center;
  }
  .c-post--teaser .c-post__body,
  .c-post--teaser .c-post__thumb {
    flex-basis: 50%;
  }
  .c-post--teaser .c-post__thumb {
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

/* -----------------------------------------------
   #AUTHORS
   ----------------------------------------------- */
/**
 * Author
 *
 * The author component is typically used to
 * display a user profile as a picture with a
 * short introduction text.
 *
 * @example markup
 *   <div class="c-avatar">
 *     <span class="c-avatar__img">
 *       <img src="…" alt=""/>
 *     </span>
 *     <strong class="c-avatar__name">
 *       Max Musterfrau
 *     </strong>
 *     <div class="c-avatar__bio>…</div>
 *   </div>
 */
.c-author {
  display: block;
  overflow: hidden;
}
.c-author:not(:last-child) {
  margin-bottom: 3rem;
}
.c-author__img {
  float: left;
  position: relative;
  overflow: hidden;
  display: block;
  width: 40%;
  height: 0;
  padding-top: 40%;
  border: 1px solid rgb(170.85, 170.85, 170.85);
  border-radius: 50%;
  margin-right: 1em;
  margin-bottom: 1em;
  shape-outside: circle();
}
.c-author__img > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.c-author__name {
  display: block;
  margin-bottom: 0.5rem;
  font-family: Cervo Neue, sans-serif;
  font-size: 24px;
  font-weight: bold;
}
.c-author__bio {
  font-size: 14px;
}

/* -----------------------------------------------
   #PROFILES
   ----------------------------------------------- */
/**
 * Profile List
 *
 * A group of related user profiles.
 *
 * @example markup
 *   <div class="c-profile-group">
 *     <div class="c-profile">
 *       …
 *     </div>
 *     <div class="c-profile">
 *       …
 *     </div>
 *     <div class="c-profile">
 *       …
 *     </div>
 *   </div>
 */
.c-profile-group {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  margin-top: 3em;
  margin-bottom: 3em;
}
@media (min-width: 768px) {
  .c-profile-group {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1088px) {
  .c-profile-group {
    grid-template-columns: repeat(3, 1fr);
  }
}
.c-profile-group__name {
  grid-column: 1/-1;
}

/**
 * User Profile
 *
 * A summary of the contact details of a generic
 * person.
 *
 * @example markup
 *   <div class="c-profile">
 *     <h2 class="c-profile__name">
 *       Max Musterfrau
 *     </h2>
 *     <div class="c-profile__role">
 *        Administrator
 *     </div>
 *     <a href="…" class="c-profile__thumb">
 *       <img src="…" alt=""/>
 *     </a>
 *   </div>
 */
.c-profile {
  display: inline-flex;
  flex-direction: column;
}
.c-profile__name {
  margin-bottom: 1rem;
}
.c-profile__role {
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: #798f93;
}
.c-profile__thumb {
  order: -1;
  margin-bottom: 1em;
}
.c-profile__thumb > img {
  width: 100%;
}

/* -----------------------------------------------
   #INTERVIEWS
   ----------------------------------------------- */
/**
 * Interview
 *
 * a structured conversation where one participant
 * asks questions, and the other provides answers.
 *
 * @example markup
 *   <div class="c-interview">
 *     <p class="c-interview__question">
 *        <span class="c-interview__interviewer">Foo:</span>
 *        What is your favorite color?
 *     </p>
 *     <p class="c-interview__answer">
 *       <span class="c-interview__interviewee">Bar:</span>
 *       Blue
 *     </p>
 *   </div>
 */
.c-interview {
  display: block;
}
.c-interview__question {
  display: block;
  font-style: bold;
}
.c-interview__answer {
  display: block;
  margin-bottom: 2rem;
  font-style: normal;
}
.c-interview__interviewer, .c-interview__interviewee {
  font-style: italic;
}

/* -----------------------------------------------
   #HOME
   ----------------------------------------------- */
/**
 * Front Page Scope
 *
 * Create a new styling context for elements on the
 * front-page.
 */
.s-home .c-header__brand {
  animation: breathe 6s ease-out infinite;
}
.s-home .c-header__brand:hover, .s-home .c-header__brand:focus {
  animation-play-state: paused;
}
@keyframes breathe {
  0% {
    transform: translateX(-50%) scale(0.92);
  }
  25% {
    transform: translateX(-50%) scale(1);
  }
  60% {
    transform: translateX(-50%) scale(0.92);
  }
  100% {
    transform: translateX(-50%) scale(0.92);
  }
}
@media (min-width: 1632px) {
  .s-home .c-header__brand {
    top: 1em;
    left: 50%;
    width: auto;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 1088px) {
  .s-home .o-post-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
  }
}

/* -----------------------------------------------
   #SINGULAR
   ----------------------------------------------- */
/**
 * Singular Page Scope
 *
 * Create a new styling context for existing single
 * posts of any type (post, page, custom post type).
 */
.s-singular .o-grid {
  margin-top: -3em;
  margin-bottom: -3em;
}

/* -----------------------------------------------
   #MARKDOWN
   ----------------------------------------------- */
/**
 * Markdown Content Block
 *
 * Create a new styling context for any free-text
 * markdown content.
 */
.s-md-content > :not([class]):not(:last-child) {
  margin-bottom: 1em;
}
.s-md-content > h2,
.s-md-content > h3,
.s-md-content > h4 {
  color: #9CDFD9;
}
.s-md-content > hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: none;
  height: 1px;
  background-color: #798f93;
}
.s-md-content ul {
  list-style: disc;
  padding-left: 1rem;
  color: #9CDFD9;
  font-family: Cervo Neue, sans-serif;
  font-size: 1.125em;
  letter-spacing: 0.25px;
}
.s-md-content ul > li {
  padding-left: 0.5rem;
}
.s-md-content a {
  color: #FD3E5C;
  transition: color 0.15s linear;
}
.s-md-content a:hover, .s-md-content a:focus {
  color: rgb(253.4, 100.6, 124.6);
}
.s-md-content a:active {
  outline: 0;
  color: rgb(227.7, 55.8, 82.8);
}

/* -----------------------------------------------
   #SPENDINO
   ----------------------------------------------- */
/**
 * Spendino Widget
 *
 * @markup
 *   <iframe id="spendinoWidget-1234">…</iframe>
 */
[id^=spendinoWidget],
[id^=spendinoForm] {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

[id^=spendinoOverlay_] {
  background-color: #FFF !important;
}

[id^=spendinoWidget] {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  background-color: transparent !important;
}

/* -----------------------------------------------
   #HEADINGS
   ----------------------------------------------- */
/**
 * A set of utility functions used to style a
 * heading independent from the used HTML tag.
 */
.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  margin-bottom: 1rem;
  font-family: Cervo Neue, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
}

.u-h1:not(:first-child),
.u-h2:not(:first-child),
.u-h3:not(:first-child) {
  margin-top: 3rem;
}

.u-h1 {
  font-size: 3.375em;
}
@media (min-width: 24em) {
  .u-h1 {
    font-size: calc(3.375em + 3.375 * (100vw - 24em) / 78);
  }
}
@media (min-width: 102em) {
  .u-h1 {
    font-size: 6.75em;
  }
}

.u-h2 {
  font-size: 54px;
}

.u-h3 {
  font-size: 36px;
  letter-spacing: 0.5px;
}

.u-h4 {
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: none;
}

.u-h5 {
  position: relative;
  top: -0.25rem;
  float: left;
  margin-right: 0.75rem;
  margin-bottom: -0.25rem;
  font-size: 27px;
}
.u-h5::after {
  content: ".";
}

/* -----------------------------------------------
   #TEXT
   ----------------------------------------------- */
/**
 * A utility function used the emphasize lead
 * paragraphs.
 */
.u-text-lead {
  text-align: center;
  font-size: 1.125em;
}
@media (min-width: 24em) {
  .u-text-lead {
    font-size: calc(1.125em + 0.375 * (100vw - 24em) / 78);
  }
}
@media (min-width: 102em) {
  .u-text-lead {
    font-size: 1.5em;
  }
}
.u-text-lead:not(:last-child) {
  margin-bottom: 3rem;
}

/**
 * Generate a set of utility classes used to change
 * the font weight.
 */
.u-text-light {
  font-weight: 300;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

/**
 * Generate a set of utility classes used to change
 * the font weight.
 */
.u-text-primary {
  color: #9CDFD9;
}

.u-text-secondary {
  color: #798f93;
}

.u-text-accent {
  color: #FD3E5C;
}

/**
 * Generate a set of utility classes used to align
 * text horizontally.
 */
.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

/**
 * Generate a set of utility classes used to
 * transform text elements.
 */
.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-lowercase {
  text-transform: lowercase;
}

.u-text-capitalize {
  text-transform: capitalize;
}

/* -----------------------------------------------
   #IMAGES
   ----------------------------------------------- */
/**
 * Scale images with the dimensions of the parent
 * element, but keep the aspect ratio of the image.
 */
.u-img-fluid {
  max-width: 100%;
  height: auto;
}

/**
 * Stretch an image to fit the dimensions of a
 * parent container.
 */
.u-img-fit {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/**
 * Apply a circular border radius to an image
 * element. The ratio of the image should be
 * 1by1.
 */
.u-img-circular {
  border-radius: 50%;
}

/**
 * A placeholder element for missing post
 * thumbnails.
 */
.u-img-placeholder {
  display: block;
  width: 100%;
  padding-top: 66.6666666667%;
  background-image: linear-gradient(15deg, #343377, #a63c60 50%, #d87476 70%, #FBB796 85%);
}

/**
 * Hide lazy loaded images if JavaScript is
 * disabled.
 */
.no-js .js-lazy-img {
  display: none;
}

/* -----------------------------------------------
   #VISIBILITY
   ----------------------------------------------- */
/**
 * Visually hide content
 *
 * A utility class used to hide content visually but
 * keep it for screen reader users.
 *
 * @example markup
 *   <a href="http://twitter.com/hksagentur">
 *     <svg>…</svg>
 *     <span class="u-sr-only">
 *       H&K+S on Twitter
 *     </span>
 *   </a>
 *
 */
.u-sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
}

/* -----------------------------------------------
   #TRUMPS
   ----------------------------------------------- */
.u-clipped {
  overflow: hidden !important;
}

.u-invisible {
  visibility: hidden !important;
}

.u-highlighted {
  color: #9CDFD9;
}

.u-paddingless {
  padding: 0 !important;
}

.u-borderless {
  border: 0 !important;
}

.u-marginless {
  margin: 0 !important;
}

.u-radiusless {
  border-radius: 0 !important;
}

.u-shadowless {
  box-shadow: none !important;
}