/* -----------------------------------------------
   #TEXT
   ----------------------------------------------- */

/// A list of available text alignments.
///
/// @type List

$text-alignments: (
  'left'
  'center'
  'right'
) !default;

/// A map of available weight aliases.
///
/// @type Map

$text-weights: (
  'light':  300,
  'normal': 400,
  'bold':   700,
) !default;

/// A map of available color aliases.
///
/// @type Map

$text-colors: (
  'primary' :  $color-primary,
  'secondary': $color-text-secondary,
  'accent' :   $color-accent,
) !default;

/// A list of available text transforms.
///
/// @type List

$text-transforms: (
  'uppercase'
  'lowercase'
  'capitalize'
) !default;



/**
 * A utility function used the emphasize lead
 * paragraphs.
 */

.u-text-lead {
  text-align: center;
  @include fluid-type($breakpoint-xs, $breakpoint-xl, 1.125 * $base-font-size, $font-size-lg);

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}



/**
 * Generate a set of utility classes used to change
 * the font weight.
 */

 @each $name, $weight in $text-weights {

.u-text-#{$name} {
    font-weight: $weight;
  }
}



/**
 * Generate a set of utility classes used to change
 * the font weight.
 */

 @each $name, $color in $text-colors {

  .u-text-#{$name} {
      color: $color;
  }
}



/**
 * Generate a set of utility classes used to align
 * text horizontally.
 */

@each $value in $text-alignments {

.u-text-#{$value} {
    text-align: unquote($value);
  }
}



/**
 * Generate a set of utility classes used to
 * transform text elements.
 */

 @each $value in $text-transforms {

.u-text-#{$value} {
    text-transform: unquote($value);
  }
}
