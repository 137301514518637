/* -----------------------------------------------
   #FOOTER
   ----------------------------------------------- */

/// Text color of the last footer item.
///
/// @type Color

$footer-text-color: $color-highlight !default;

/// Background color of the last footer item.
///
/// @type Color

$footer-background-color: $color-sherpa-blue !default;



/**
 * Page Footer
 *
 * A website’s footer is an area located at the
 * bottom of every page on a website, below the main
 * content.
 *
 * @example markup
 *   <footer class="c-footer">
 *     <div class="c-footer__item">
 *       …
 *     </div>
 *     <div class="c-footer__item">
 *       …
 *     </div>
 *   </footer>
 */

.c-footer {
  display: flex;
  flex-direction: column;
  margin-top: 3em;

  &__item {
    padding-top: 1em;
    padding-bottom: 1em;

    &:last-child {
      color: $color-highlight;
      text-align: right;
      background-color: $color-background-dark;

      @media (max-width: #{$breakpoint-sm}) {
        margin-top: 3em;
      }
    }
  }
}
