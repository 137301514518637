/* ----------------------------------------------
   #CONTAINER
   ---------------------------------------------- */

/// The maximum width of the container object.
///
/// @type Number

$container-width: 1280px !default;

/// The maximum width of a narrow container.
///
/// @type Number

$container-width-narrow: 768px !default;

/// The maximum width of a wide container.
///
/// @type Number

$container-width-wide: 1632px !default;



/**
 * Content Container
 *
 * A content container helps to limit the
 * maximum width of a page element and avoids
 * repititon of code.
 *
 * @example markup
 *  <div class="o-container">
 *    …
 *  </div>
 */

.o-container {
  width: 100%;
	max-width: $container-width;
	padding-left: 1em;
	padding-right: 1em;
	margin-left: auto;
  margin-right: auto;

  &--narrow {
    max-width: $container-width-narrow;
  }

  &--wide {
    max-width: $container-width-wide;
  }
}
