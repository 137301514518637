/* -----------------------------------------------
   #LIST-BARE
   ----------------------------------------------- */

/**
 * Inline Bare
 *
 * The inline-list object remoes list-like
 * appearance from elements by removing bullets and
 * indentation.
 *
 * @example markup
 *   <dl class="o-list-bare">
 *     <dt class="o-list-bare__item">…</li>
 *     <dd class="o-list-bare__item">…</li>
 *     <dt class="o-list-bare__item">…</li>
 *     <dd class="o-list-bare__item">…</li>
 *   </dl>
 */

.o-list-bare {
  list-style: none;
  margin-left: 0;

  &__item {
    margin-left: 0;
  }
}
