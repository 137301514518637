/* ----------------------------------------------
   #FORMS
   ---------------------------------------------- */

/**
 * Form
 *
 * A form is a container for multiple input fields.
 *
 * @example markup
 *   <form class="c-form" method="POST" action="">
 *     …
 *   </form>
 */

.c-form {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 48em;
  margin: 3em auto;
  padding: 1em;

  > * {
    grid-column: 1 / -1;
  }

  @media (min-width: 54em) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 0.75em;
    padding: 3em;
    border: 1px solid $color-gray-89;
    border-radius: 0.25em;

    > [data-width="1/2"] {
      grid-column: span 6;
    }

    > [data-width="1/3"] {
      grid-column: span 4;
    }

    > [data-width="2/3"] {
      grid-column: span 8;
    }
  }
}



/**
 * Field
 *
 * A form field is a container for a text label, the
 * actual form control and some optional help.
 *
 * @example markup
 *   <div class="c-field">
 *     <label for="email" class="c-field__label">
 *       E-Mail
 *       <span>
 *         (Pflichtfeld)
 *       </span>
 *     </label>
 *     <input type="email" id="email" name="email" class="c-input" value="" />
 *     <p class="c-field__help">
 *       Please provide a valid email address.
 *     </p>
 *   </div>
 */

.c-field {
  display: block;
  margin-bottom: 0.75em;

  // Field label
  &__label {
    display: block;
    font-weight: 700;
    margin-bottom: 0.25em;

    > span {
      color: $color-accent;
    }
  }

  // Field help
  &__help {
    display: block;
    font-size: $font-size-sm;
    margin-top: 0.5em;

    .c-field--is-valid & {
      color: $color-success;
    }

    .c-field--is-invalid & {
      color: $color-error;
    }
  }

  // Honeypot Field
  &.\🐝 {
    position: absolute;
    left: -999em;
  }
}



/**
 * Text Input
 *
 * Form controls for textual user input.
 *
 * @example markup
 *   <input type="text" value="" class="c-input" />
 */

.c-input {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  border-radius: 0.125em;
  border: 1px solid $color-input-border;
  color: $color-text-primary;
  font-size: 1rem;
  font-family: inherit;
  line-height: $base-line-height;
  background-color: $color-highlight;
  box-shadow: inset 0 1px 2px rgba($color-shadow, 0.1);

  &:hover {
    border-color: shade($color-input-border, 15%);
  }

  &:focus {
    border-color: $color-focus;
    box-shadow: 0 0 0 .125em rgba($color-focus, 0.25);
  }

  &[disabled] {
    color: $color-text-disabled;
    border-color: tint($color-disabled, 30%);
    background-color: tint($color-disabled, 50%);
    box-shadow: none;
    cursor: not-allowed;
  }

  &[rows] {
    height: initial;
  }

  &--is-valid {
    border-color: $color-success;

    &:hover {
      border-color: $color-success;
    }

    &:focus {
      box-shadow: 0 0 0 0.125em rgba($color-success, 0.25);
    }
  }

  &--is-invalid {
    border-color: $color-error;

    &:hover {
      border-color: $color-error;
    }

    &:focus {
      box-shadow: 0 0 0 0.125em rgba($color-error, 0.25);
    }
  }
}



/**
 * Checkbox
 *
 * A checkbox allows one or multiple values to be
 * selected from a list of choices
 *
 * @example markup
 *   <label class="c-checkbox">
 *      <input type="checkbox" class="c-checkbox__control">
 *      Remember Password
 *   </label>
 */

.c-checkbox {
  position: relative;
  display: block;
  padding-left: 1.5em;

  &__control {
    position: absolute;
    left: 0;
    top: 0.375em;
  }
}
