// -----------------------------------------------
// #TYPOGRAPHY
// -----------------------------------------------

/// Viewport sized property with minimum and
/// maximum values.
///
/// @author Mike Riethmuller
/// @see {https://www.madebymike.com.au/writing/precise-control-responsive-typography/}
///
/// @param {List} $properties - A list of properties to scale.
/// @param {Number} $min-vw - Smallest viewport width.
/// @param {Number} $max-vw - Largest viewport width.
/// @param {Number} $max-value - Minimum font size.
/// @param {Number} $max-value - Maximum font size.

@mixin fluid-property($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @if not same-unit(unit($min-value), $min-vw, $max-vw, $min-value, $max-value) {
    @warn "The given numbers should use the same unit";
  }

  @if (unit($min-vw) == 'px') {
    $min-vw: em($min-vw);
  }

  @if (unit($max-vw) == 'px') {
    $max-vw: em($max-vw);
  }

  @if (unit($min-value) == 'px') {
    $min-value: em($min-value);
  }

  @if (unit($max-value) == 'px') {
    $max-value: em($max-value);
  }

  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

/// Viewport sized typography with minimum and
/// maximum values.
///
/// @author Mike Riethmuller
/// @see {https://www.madebymike.com.au/writing/precise-control-responsive-typography/}
///
/// @param {Number} $min-vw - Smallest viewport width.
/// @param {Number} $max-vw - Largest viewport width.
/// @param {Number} $max-value - Minimum font size.
/// @param {Number} $max-value - Maximum font size.

@mixin fluid-type($min-vw, $max-vw, $min-value, $max-value) {
  @include fluid-property(font-size, $min-vw, $max-vw, $min-value, $max-value);
}
