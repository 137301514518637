/* ----------------------------------------------
   #ACCORDIONS
   ---------------------------------------------- */

/// Text color used for accordion contents.
///
/// @type Color

$accordion-text-color: $color-text-primary !default;

/// Background color used for the accordion container.
///
/// @type Color

$accordion-background-color: $color-primary !default;

/// Font family used for accordion contents.
///
/// @type String

$accordion-font-family: $font-family-sans !default;



/**
 * Accordion Component
 *
 * A simple disclosure widget, where the visibility
 * of the inner content can be toggled on click.
 *
 * @example markup
 *   <details class="c-accordion">
 *     <summary class="c-accordion__summary">
 *       Heading
 *     </summary>
 *     …
 *   </details>
 */

.c-accordion {
  display: block;
  margin-bottom: 1rem;
  color: $accordion-text-color;
  font-family: $accordion-font-family;
  background-color: $accordion-background-color;

  > * {
    color: inherit !important;
  }

  &,
  &__summary {
    display: inline-block;
    vertical-align: middle;
    padding: 1rem 1.75rem;
  }

  &__summary {
    margin: -1rem -1.75rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;

    [open] & {
      display: block;
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: relative;
      top: -0.0625em;
      display: inline-block;
      vertical-align: middle;
      width: 0.75em;
      height: 0.75em;
      margin-right: 1em;
      border-top: 0.25em solid currentColor;
      border-right: 0.25em solid currentColor;
      transform: rotate(45deg);
      transition: transform $duration-promptly ease-in-out;

      [open] & {
        transform: translateY(-25%) rotate(135deg);
      }
    }
  }
}
