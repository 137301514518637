/* ----------------------------------------------
   #BANNERS
   ---------------------------------------------- */

/**
 * Banner Component
 *
 * A simple, stylized container to divide your page
 * into visual distinctive sections.
 *
 * @example markup
 *   <section class="c-banner">
 *     <header class="c-banner__header">
 *       <h2 class="c-banner__title">
 *         Section Title
 *       </h2>
 *       <img src="…" class="c-header__figure" alt=""/>
 *     </header>
 *     <div class="s-md-content">
 *       <p>Lorem …</p>
 *     </div>
 *   </section>
 */

.c-banner {
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 3em;
  margin-bottom: 3em;

  &__header {
    position: relative;
    overflow: hidden;
    padding-top: 15vmax;
    margin-top: -3em;
    margin-bottom: 3em;
    text-align: center;
  }

  &__title {
    position: relative;
    z-index: $layer-above;
    padding: 1rem;
    color: $color-primary;
    opacity: 0.9;
    text-shadow:
      0 10px 20px rgba($color-shadow, 0.15),
      0 6px 6px rgba($color-shadow, 0.18);
  }

  &__figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}



/**
 * Color Variations
 *
 * A set of modifiers used to customize the color of
 * a banner component.
 */

.c-banner--dark {
  color: $color-highlight;
  background-color: $color-background-dark;
}

.c-banner--tint {
  color: $color-text-primary;
  background-color: $color-background-light;
}
