/* -----------------------------------------------
   #LINKS
   ----------------------------------------------- */

/// Text color of link elements.
///
/// @type Color

$link-color: $color-accent !default;



/**
 * Link Styles
 *
 * By default links have no styling applied.
 * Use this component to emphasize that a
 * text element is a link to another document.
 */

.c-link {
  color: $link-color;
  transition: color $duration-promptly linear;

  &:hover,
  &:focus {
    color: tint($link-color, 20%);
  }

  &:active {
    outline: 0;
    color: shade($link-color, 10%);
  }
}
