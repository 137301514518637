/* -----------------------------------------------
   #HEADER
   ----------------------------------------------- */

/// Maximum width of the hero content.
///
/// @type Number

$hero-content-width: $container-width-wide !default;

/// Minimum amount of padding added to the hero
/// content.
///
/// @type Number

$hero-content-offset: 192px !default;

/// The text color of hero elements.
///
/// @type Color

$hero-text-color: $color-primary !default;

/// The background color of the hero element.
///
/// @type Color

$hero-background-color: $color-primary !default;



/**
 * Hero Banner
 *
 * The hero component is an imposing banner to
 * showcase something.
 *
 * @example markup
 *   <header class="c-hero">
 *     <h1 class="c-hero__title">
 *       Hello World!
 *     </h1>
 *     <img src="…" class="c-hero__cover-image" alt=""/>
 *   </header>
 */

.c-hero {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  padding-top: $hero-content-offset;
  padding-bottom: 2em;
  margin-bottom: 3em;
  color: $hero-text-color;
  text-align: center;
  background-color: $hero-background-color;
  background-image: $gradient-primary;

  @media
    (min-height: #{4 * $hero-content-offset}),
    (min-width: #{4 * $hero-content-offset}) {
    padding-top: 25vmax;
  }

  // Hero Image Overlay
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15%;
    max-height: 128px;
    background: url('../images/bg-header.jpg') top center / cover no-repeat;

    @media
      (min-width: #{$breakpoint-lg}),
      (min-device-pixel-ratio: 1.25) {
      background-image: url('../images/bg-header@2x.jpg');
    }
  }

  // Hero Title
  &__title {
    position: relative;
    z-index: $layer-above;
    width: 100%;
    max-width: $hero-content-width;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.9;
    color: $hero-text-color;
    text-shadow:
      0 10px 20px rgba($color-shadow, 0.15),
      0 6px 6px rgba($color-shadow, 0.18);
  }

  // Hero Cover Image
  &__cover-image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}



/**
 * Size Variations
 *
 * The hero component is an imposing banner to
 * showcase something.
 */

.c-hero--invincible {

  // Hero Image Overlay
  &::after {
    display: none;
  }

  // Hero Title
  .c-hero__title {
    max-width: 64rem;
    color: $color-highlight;
    line-height: 0.9;
    @include fluid-type($breakpoint-xs, $breakpoint-xl, $font-size-xl, 192px);
  }
}
