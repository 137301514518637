/* -----------------------------------------------
   #SINGULAR
   ----------------------------------------------- */

/**
 * Singular Page Scope
 *
 * Create a new styling context for existing single
 * posts of any type (post, page, custom post type).
 */

.s-singular {

  .o-grid {
    margin-top: -3em;
    margin-bottom: -3em;
  }
}
