/* -----------------------------------------------
   #NAV
   ----------------------------------------------- */

/// Horizontal spacing to add between navigation
/// items.
///
/// @type Number

$nav-gutter: 1em !default;

/// The font family used for navigation items.
///
/// @type String

$nav-font-family: $font-family-sans !default;

/// The font size of navigation links.
///
/// @type Number

$nav-font-size: $font-size-lg !default;

/// Color of a navigation link, when currently
/// active.
///
/// @type Color

$nav-link-color: $color-text-primary !default;

/// Color of a navigation link, when currently
/// active.
///
/// @type Color

$nav-link-color-active: $color-accent !default;

/// Text color of the off-canvas menu.
///
/// @type Color

$nav-offcanvas-text-color: $color-highlight !default;

/// Background of the off-canvas menu.
///
/// @type Color

$nav-offcanvas-background-color: $color-background-dark !default;




/**
 * Site Navigation
 *
 * The site navigation contains links to the various
 * pages of a website.
 *
 * @example markup
 *   <nav class="c-site-nav">
 *     <ul class="c-site-nav__menu">
 *       <li class="c-site-nav__item">
 *         <a class="c-site-nav__link" href="…">…</a>
 *       </li>
 *       <li class="c-site-nav__item">
 *         <a class="c-site-nav__link" href="…">…</a>
 *       </li>
 *     </ul>
 *   </nav>
 */

.c-site-nav {
  position: relative;
  width: 100%;

  &__toggle-button {
    display: none;
    position: fixed;
    top: 2em;
    right: 1em;
    z-index: $layer-menu + 1;
    padding: 0.5em;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      z-index: -1;
      background-color: rgba($color-highlight, 0.9);
      transition: transform $duration-promptly ease;
    }

    &[aria-expanded="true"] {
      color: $color-highlight;
      background-color: $color-background-dark;

      &::after {
        transform: scale(0);
      }
    }
  }

  &__menu {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -$nav-gutter;
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    margin-left: $nav-gutter;
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    padding: 0.75rem 1rem;
    font-family: $nav-font-family;
    font-size: $nav-font-size;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    white-space: nowrap;
    transition: color $duration-promptly linear;

    &:hover,
    &:focus {
      color: $nav-link-color-active;
    }

    &:active {
      outline: 0;
    }

    &--is-active {
      padding-top: 2rem;
      margin-bottom: -2rem;
      color: $nav-link-color;
      background-color: $color-highlight;
    }
  }

  /**
   * Mobile Site Navigation
   *
   * Collapse the navigation for mobile devices and
   * display navigation entries in an off-canvas
   * menu.
   */

  @media (max-width: #{$breakpoint-lg}) {

    // Navigation Toggle-Button
    &__toggle-button {
      display: inline-block;
    }

    // Off-canvas Menu
    &__menu {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: $layer-menu;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      padding-top: 10vh;
      padding-bottom: 10vh;
      opacity: 0;
      visibility: hidden;
      color: $nav-offcanvas-text-color;
      background-color: $nav-offcanvas-background-color;
      transition:
        opacity 0.2s 0.3s linear,
        visibility 0.2s 0.3s linear;

      .is-menu-open & {
        opacity: 1;
        visibility: visible;
        transition:
          opacity 0.2s linear,
          visibility 0.2s linear;
      }
    }

    // Navigation Item
    &__item {
      display: block;
      width: 100%;
      margin-left: 0;
    }

    // Navigation Link
    &__link {
      position: relative;
      font-size: 2 * $nav-font-size;
      color: $color-highlight;
      background-color: transparent;

      &--is-active {
        padding-top: 0.75rem;
        margin-bottom: 0;

        &::after {
          content: '';
          position: absolute;
          left: 1rem;
          right: 1rem;
          bottom: 0;
          display: block;
          height: 3px;
          margin-bottom: 0.375em;
          background-color: $color-accent;
          transition: transform $duration-promptly ease;
        }

        &:focus::after,
        &:hover::after {
          transform: scaleX(1.05);
        }
      }
    }
  }
}



/**
 * Navigation
 *
 * A generic navigation menu contains links to the
 * a group of pages.
 *
 * @example markup
 *   <nav class="c-nav">
 *     <ul class="c-nav__menu">
 *       <li class="c-nav__item">
 *         <a class="c-nav__link" href="…">…</a>
 *       </li>
 *       <li class="c-nav__item">
 *         <a class="c-nav__link" href="…">…</a>
 *       </li>
 *     </ul>
 *   </nav>
 */

.c-nav {
  position: relative;
  width: 100%;

  &__menu {
    display: flex;
    align-items: baseline;
    margin-top: -$nav-gutter;
    margin-left: -$nav-gutter;
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    margin-top: $nav-gutter;
    margin-left: $nav-gutter;
  }

  &__link {
    display: block;
    font-family: $nav-font-family;
    font-size: 1.125em;
    line-height: 1.2;
    transition: color $duration-promptly linear;

    &:hover,
    &:focus {
      color: $nav-link-color-active;
    }

    &:active {
      outline: 0;
    }

    &--strong {
      padding: 3rem 2rem 2rem;
      margin-top: -3rem;
      margin-bottom: -1rem;
      font-size: $font-size-lg;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: $color-highlight;
      background-color: $nav-link-color-active;
      @include elevate(3);

      &:hover,
      &:focus {
        color: tint($nav-link-color-active, 75%);
      }
    }
  }
}



/**
 * Customize element order
 *
 * Customize the order navigation items are
 * rendered.
 */

.c-nav--center {

  > .c-nav__menu {
    justify-content: center;
  }
}

.c-nav--right {

  > .c-nav__menu {
    justify-content: flex-end;
  }
}

.c-nav--reverse {

  > .c-nav__menu {
    flex-direction: row-reverse;
  }
}
