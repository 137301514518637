/* -----------------------------------------------
   #INTERVIEWS
   ----------------------------------------------- */

/**
 * Interview
 *
 * a structured conversation where one participant
 * asks questions, and the other provides answers.
 *
 * @example markup
 *   <div class="c-interview">
 *     <p class="c-interview__question">
 *        <span class="c-interview__interviewer">Foo:</span>
 *        What is your favorite color?
 *     </p>
 *     <p class="c-interview__answer">
 *       <span class="c-interview__interviewee">Bar:</span>
 *       Blue
 *     </p>
 *   </div>
 */

.c-interview {
  display: block;

  &__question {
    display: block;
    font-style: bold;
  }

  &__answer {
    display: block;
    margin-bottom: 2rem;
    font-style: normal;
  }

  &__interviewer,
  &__interviewee {
    font-style: italic;
  }
}
