/*! Lungenstiftung Bremen | 2019 */

// SETTINGS & TOOLS:
// -------------------------------------
// Variables, mixins and functions
// -------------------------------------

@import 'settings/settings.typography';
@import 'settings/settings.colors';
@import 'settings/settings.gradients';
@import 'settings/settings.layers';
@import 'settings/settings.durations';
@import 'settings/settings.easings';
@import 'settings/settings.breakpoints';

@import 'tools/tools.math';
@import 'tools/tools.utilities';
@import 'tools/tools.typography';
@import 'tools/tools.colors';
@import 'tools/tools.elevate';
@import 'tools/tools.visibility';



// GENERIC:
// -------------------------------------
// Ground-zero styles
// -------------------------------------

@import 'generic/generic.fonts';
@import 'generic/generic.boxsizing';
@import 'generic/generic.reboot';
@import 'generic/generic.document';



// OBJECTS:
// -------------------------------------
// Cosmetic-free design patterns
// -------------------------------------

@import 'objects/objects.container';
@import 'objects/objects.layout';
@import 'objects/objects.grid';
@import 'objects/objects.bundle';
@import 'objects/objects.icon';
@import 'objects/objects.list-bare';
@import 'objects/objects.list-inline';



// COMPONENTS:
// -------------------------------------
// Styled UI components
// -------------------------------------

@import 'components/components.header';
@import 'components/components.footer';
@import 'components/components.nav';
@import 'components/components.menu';
@import 'components/components.skip';
@import 'components/components.hero';
@import 'components/components.links';
@import 'components/components.cards';
@import 'components/components.tiles';
@import 'components/components.banners';
@import 'components/components.buttons';
@import 'components/components.callouts';
@import 'components/components.forms';
@import 'components/components.pagination';
@import 'components/components.accordions';
@import 'components/components.tags';
@import 'components/components.posts';
@import 'components/components.authors';
@import 'components/components.profiles';
@import 'components/components.interviews';



// SCOPES:
// -------------------------------------
// Various styling contexts
// -------------------------------------

@import 'scopes/scopes.home';
@import 'scopes/scopes.singular';
@import 'scopes/scopes.markdown';



// VENDOR:
// -------------------------------------
// 3rd-Party resources
// -------------------------------------

@import 'vendor/vendor.spendino';



// UTILITIES:
// -------------------------------------
// Helpers and overwrites
// -------------------------------------

@import 'utilities/utilities.headings';
@import 'utilities/utilities.text';
@import 'utilities/utilities.images';
@import 'utilities/utilities.visibility';
@import 'utilities/utilities.trumps';
