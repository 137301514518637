/* -----------------------------------------------
   #FONTS
   ----------------------------------------------- */

// @font-face {
// 	font-family: Cervo Neue;
//   src: url('../fonts/cervo-neue-light.woff2') format('woff2'),
//        url('../fonts/cervo-neue-light.woff') format('woff');
//   font-display: swap;
//   font-weight: 300;
// }

// @font-face {
// 	font-family: Cervo Neue;
//   src: url('../fonts/cervo-neue-medium.woff2') format('woff2'),
//        url('../fonts/cervo-neue-medium.woff') format('woff');
//   font-display: swap;
//   font-weight: 500;
// }

// @font-face {
// 	font-family: Cervo Neue;
//   src: url('../fonts/cervo-neue-semibold.woff2') format('woff2'),
//        url('../fonts/cervo-neue-semibold.woff') format('woff');
//   font-display: swap;
//   font-weight: 600;
// }

@font-face {
	font-family: Cervo Neue;
  src: url('../fonts/cervo-neue-regular.woff2') format('woff2'),
       url('../fonts/cervo-neue-regular.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
	font-family: Cervo Neue;
  src: url('../fonts/cervo-neue-bold.woff2') format('woff2'),
       url('../fonts/cervo-neue-bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
	font-family: Karma;
  src: url('../fonts/karma-regular.woff2') format('woff2'),
       url('../fonts/karma-regular.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
	font-family: Karma;
  src: url('../fonts/karma-bold.woff2') format('woff2'),
       url('../fonts/karma-bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}
